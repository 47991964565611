










































































































import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import { Proto } from '@/player/model/proto'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import app from '@/main'
import { parsePhoneNumber } from 'libphonenumber-js'
import { PlayerApiService } from '@/player/service/api.service'
import { finalize, takeUntil } from 'rxjs/operators'
import AlertModal from '@/player/components/modal/AlertModal.vue'
import { required } from 'vee-validate/dist/rules'
import { State } from '@/store'
import { emailRegex } from '@/player/model/constant'

@Component({
  components: {
    CloseIcon,
    ValidationObserver,
    ValidationProvider
  }
})
export default class ContactEmailModal extends AbstractModalVue {
  public static NAME = 'contact-email-modal'

  @Prop() planName!: string
  @Prop() content!: Proto.IContent | null

  model = {
    name: '',
    email: '',
    phone: '',
    body: '',
    emailReadonly: false,
    contactCheck: false
  }

  link = {
    userPolicy: process.env.VUE_APP_USER_POLICY,
    privatePolicy: process.env.VUE_APP_PRIVATE_POLICY
  }

  submitting = false

  created() {
    extend('required', {
      ...required,
      message: this.$tc('player.error.msg.field_required') as string
    })

    extend('requiredTrue', {
      validate: (value: boolean | undefined | null): boolean => {
        return !!value
      },
      message: this.$tc('player.text.modal.contact.email.privacy_policy_check')
    })

    configure({
      classes: {
        invalid: 'is-invalid',
        dirty: 'is-dirty'
      }
    })

    extend('email', value => {
      try {
        if (emailRegex.test(value)) {
          return true
        }
      } catch (e) {
      }
      return app.$t('error.msg.field_invalid', { _field_: app.$tc('player.text.modal.contact.email.form.email') }) as string
    })

    extend('phone', value => {
      try {
        if (/^[0０]/.test(value) && parsePhoneNumber(value, 'JP').isValid()) {
          return true
        }
      } catch (e) {
      }
      return app.$t('error.msg.field_invalid', { _field_: app.$tc('player.text.modal.contact.email.form.phone') }) as string
    })

    try {
      const staticPath = (this.$store.state as State).playerMeta.staticPath
      if (staticPath) {
        this.link.userPolicy = `https://liteview.jp/static/${staticPath}/user_policy.html`
        this.link.privatePolicy = `https://liteview.jp/static/${staticPath}/privacy_policy.html`
      } else {
        this.link.userPolicy = process.env.VUE_APP_USER_POLICY
        this.link.privatePolicy = process.env.VUE_APP_PRIVATE_POLICY
      }
    } catch (e) {
      this.$log.error(e)
    }

    if (this.uuid) {
      this.submitting = true
      PlayerApiService.findUser(true)
        .pipe(takeUntil(this.unsubscribe$), finalize(() => {
          this.submitting = false
        }))
        .subscribe(user => {
          this.model.email = user.userId || ''
          if (user.userLastname && user.userFirstname) {
            this.model.name = user.userLastname + user.userFirstname
          } else {
            this.model.name = user.userNickname || ''
          }
          this.model.phone = user.phone || ''
          if (this.model.email) {
            this.model.emailReadonly = true
          }
        }, e => {
          this.$log.error(e)
        })
    }
  }

  destroyed() {
    super.destroyed()
  }

  get uuid(): string | undefined {
    return (this.$store.state as State).playerAuth.uuid
  }

  validatePhone(value: string): boolean {
    try {
      return /^[0０]/.test(value) && parsePhoneNumber(value, 'JP').isValid()
    } catch (e) {
      return false
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autocompletePhone(form: any): void {
    const value = form.phone
    if (!value || !this.validatePhone(value)) {
      return
    }
    if (!this.validatePhone(value)) {
      return
    }
    let phone = parsePhoneNumber(value as string, 'JP').formatNational()
    if (phone && !phone.startsWith('0')) {
      phone = '0' + phone
    }
    if (phone !== value) {
      form.phone = phone
    }
  }

  send(event: Event, vForm) {
    event.preventDefault()
    event.stopPropagation()

    vForm.handleSubmit(() => {
      if (this.submitting) {
        return
      }

      this.$Progress.start()
      this.submitting = true
      PlayerApiService.contactEmail(this.model.name, this.model.email, this.model.phone, this.model.body, this.content ? this.content.uid as string : undefined)
        .pipe(finalize(() => {
          this.$Progress.finish()
          this.submitting = false
        }))
        .subscribe((email) => {
          this.hide()
          const name = AlertModal.NAME + '-' + Math.random()
          this.showModal(name, AlertModal, {
            title: this.$tc('player.text.modal.contact.email.title', undefined, { name: this.planName }),
            message: this.$tc('player.text.modal.contact.email.success', undefined, { email: email })
          })
        }, e => {
          this.$log.error(e)
          this.showAlert(e)
        })
    })
  }

  hide() {
    super.hide()
  }
}
