import { AuthFailedPayload, LoginActionPayload, LogoutActionPayload } from '@/store/auth/action'

export type State = {
  isInitialized: boolean;
  loggedIn: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
}

export const state: State = {
  isInitialized: false,
  loggedIn: false,
  user: undefined
}

const mutations = {
  [LoginActionPayload.type](state: State, payload: LoginActionPayload) {
    state.isInitialized = state.loggedIn = true
    state.user = payload.user
  },
  [LogoutActionPayload.type](state: State) {
    state.loggedIn = false
    state.user = undefined
  },
  [AuthFailedPayload.type](state: State) {
    state.isInitialized = true
  }
}

export default {
  state,
  mutations
}
