import { Vue } from 'vue-property-decorator'
import { PlayerUIService } from '@/player/service/ui.service'
import { ContentType } from '@/player/model/constant'
import { State } from '@/store'
import { getPagePath, SiteDesignData, SiteDesignPageType } from '@/model/template'
import { BrowserService } from '@/service/browser.service'
import app from '@/main'

export const notificationBusService = (function () {
  const bus = new Vue()
  let isSubscribing = false
  let serviceWorkerOn = false
  return {
    bus,
    getSubscribing(): boolean {
      return isSubscribing
    },
    setSubscribing(on: boolean): void {
      isSubscribing = on
    },
    getServiceWorkerOn(): boolean {
      return serviceWorkerOn
    },
    setServiceWorkerOn(on: boolean): void {
      serviceWorkerOn = on
      bus.$emit('service-worker-ready')
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleNotification(item: any,
      state: State,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      boardData?: any,
      isOwner?: boolean,
      needMobileForChat?: () => void): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const meta: any = item.meta ? JSON.parse(item.meta) : {}
      app.$log?.debug(item)
      switch (item.type) {
        case 1: // liveStart
        case 2: // newContent
        case 6: // customContent
        case 7: // contentSchedule
        case 8: // contentScheduleStart
        case 9: // contentScheduleDone
        case 41: // content comment mention
          if (item.metaGUID && item.metaPlaylistUID) {
            if (meta.t) {
              PlayerUIService.openContentWithType(item.metaPlaylistUID, item.metaGUID, parseInt(meta.t, 10))
            } else {
              PlayerUIService.openContentWithType(item.metaPlaylistUID, item.metaGUID, ContentType.Video)
            }
          }
          break
        case 3: // alert
        case 4: // chat
        case 12: // BoardGroupAlert
        case 13: // BoardGroupChat
          if (boardData && boardData.webTalkType === 100) {
            let path = ''
            try {
              const t = state.meta.template
              const template = SiteDesignData.from(t.content)
              path = getPagePath(template, SiteDesignPageType.Chatroom, 'chatroom')
            } catch (e) {
              app.$log?.error(e)
            }
            if (meta.group) {
              if (isOwner) {
                if (meta.mui) {
                  BrowserService.jumpToPath(`/${path || 'chatroom'}/chat-admin/${meta.group}`)
                } else {
                  BrowserService.jumpToPath(`/${path || 'chatroom'}/group/${meta.group}`)
                }
              } else {
                BrowserService.jumpToPath(`/${path || 'chatroom'}/group/${meta.group}`)
              }
            } else if (meta.mc) {
              if (isOwner) {
                if (meta.mui) {
                  BrowserService.jumpToPath(`/${path || 'chatroom'}/chat-admin/${meta.mui}`)
                }
              } else {
                BrowserService.jumpToPath(`/${path || 'chatroom'}/chat/${meta.mc}`)
              }
            }
          } else {
            if (needMobileForChat) {
              needMobileForChat()
            }
          }
          break
        case 5: // custom
          break
        case 10: // boardTopicAdded
        case 11: // boardCommentReply
        case 14: // BoardTopicReaction
        case 15: // BoardTopicComment
        case 16: // BoardTopicCommentReaction
        case 17: // BoardTopicCommentMention
          if (meta.bc && meta.board && meta.topic) {
            let path = ''
            try {
              const t = state.meta.template
              const template = SiteDesignData.from(t.content)
              path = getPagePath(template, SiteDesignPageType.ForumList, 'forum')
            } catch (e) {
              app.$log?.error(e)
            }
            BrowserService.jumpToPath(`/${path || 'forum'}/${meta.forum || '0'}/${meta.topic}`)
          }
          break
        case 31: // WorkflowRemind
          if (meta.builderUID) {
            let path = ''
            try {
              const t = state.meta.template
              const template = SiteDesignData.from(t.content)
              path = getPagePath(template, SiteDesignPageType.Workflow, 'workflow')
            } catch (e) {
              app.$log?.error(e)
            }
            BrowserService.jumpToPath(`/${path || 'workflow'}`, `builder=${meta.builderUID}`)
          }
          break
        case 32: // WorkflowNew
        case 33: // WorkflowState
        case 34: // WorkflowRemindStatus
        case 35: // WorkflowChangeUser
          if (meta.builderUID) {
            BrowserService.jumpToLiteviewPath(`/member/account/workflow/${meta.builderUID}`)
          }
          break
      }
    }
  }
})()
