import app from '@/main'
import * as UAParser from 'ua-parser-js'
import { getBaseUrl } from '@/router'
import { SiteDesignData, SiteDesignSectionDataType, SiteDesignSectionType } from '@/model/template'
import { State } from '@/store'
import { Dictionary } from 'vue-router/types/router'

let isFaviconSet = false

export class BrowserService {
  public static isIE(): boolean {
    return /Trident\/|MSIE /.test(window.navigator.userAgent)
  }

  public static isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') >= 0
  }

  public static isSafari(): boolean {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return Object.prototype.toString.call((window as any).HTMLElement).indexOf('Constructor') > 0 || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
      /* eslint-disable @typescript-eslint/no-explicit-any */
    })(!(window as any).safari || (window as any).safari.pushNotification)
  }

  public static isCrawler(): boolean {
    try {
      return /(Googlebot|Prerender|bingbot|baiduspider|yandex|yeti|yodaobot|gigabot|ia_archiver|facebookexternalhit|twitterbot|Discordbot|SkypeUriPreview|PlurkBot|Slackbot|Twitterbot|Linespider|bot)/ig.test(navigator.userAgent)
    } catch (e) {
      console.error(e)
      return false
    }
  }

  public static isPrerender(): boolean {
    try {
      return /(Prerender)/ig.test(navigator.userAgent)
    } catch (e) {
      console.error(e)
      return false
    }
  }

  public static isPC(): boolean {
    const parser = new UAParser().getResult()
    return !parser.device.type && !BrowserService.isIOS() && !BrowserService.isAndroid()
  }

  public static isMobileDevice(): boolean {
    const parser = new UAParser().getResult()
    return parser.device?.type === 'mobile'
  }

  public static isTabletDevice(): boolean {
    const parser = new UAParser().getResult()
    return parser.device?.type === 'tablet'
  }

  public static isIOS(): boolean {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || BrowserService.isIpadOS()
  }

  public static isIpadOS(): boolean {
    return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  }

  public static isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1
  }

  public static isApp(): boolean {
    try {
      return !!navigator.userAgent && (navigator.userAgent.indexOf('LITEVIEW/iOS') >= 0 || navigator.userAgent.indexOf('LITEVIEW/Android') >= 0)
    } catch (e) {
      return false
    }
  }

  public static getAppVersion(): number[] | null {
    try {
      const reg = new RegExp('LITEVIEW\\/(iOS|Android)\\/([\\d\\.]+)\\/\\d+\\s*$', 'gm')
      const match = reg.exec(navigator.userAgent)
      if (match) {
        const versions = match[2].split('.')
        return versions.map(v => parseInt(v, 10))
      }
    } catch (ignore) {

    }
    return null
  }

  public static isInIframe(defaultValue?: boolean): boolean {
    try {
      return window.self !== window.top
    } catch (e) {
      return defaultValue ?? false
    }
  }

  public static encodeHtml(string: string): string {
    try {
      const body = string || ''
      return body.replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    } catch (e) {
      return string
    }
  }

  public static dispatchEvent(
    event: string,
    dict?: CustomEventInit,
    elm: EventTarget = window,
    eventInterface?: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).CustomEvent) {
      const eve = new CustomEvent(event, dict)
      elm.dispatchEvent(eve)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((document as any).documentMode) {
      const eve = document.createEvent(eventInterface || 'Event')
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      eve.initEvent(event, dict && dict.bubbles, dict && dict.cancelable, eventData)
      elm.dispatchEvent(eve)
    } else {
      elm.dispatchEvent(new Event(event, dict))
    }
  }

  public static shouldAsyncLoadByScroll(target: HTMLElement | Document): boolean {
    if (target === document) {
      const range = window.scrollY / (document.documentElement.scrollHeight - document.documentElement.offsetHeight)
      return range > 0.98
    } else {
      const tag = target as HTMLElement
      if (tag.scrollTop) {
        const range = tag.scrollTop / (tag.scrollHeight - tag.offsetHeight)
        return range > 0.98
      }
    }
    return false
  }

  public static downloadFile(url: string) {
    const link = document.createElement('a')
    let name = new URL(url).pathname
    const index = name.lastIndexOf('/')
    if (index >= 0) {
      name = name.substr(index + 1)
    }
    link.setAttribute('target', '_blank')
    link.setAttribute('download', name)
    link.href = url
    link.style.opacity = '0'
    // document.body.appendChild(link)
    link.click()
    // link.remove()
  }

  public static createMetaTag(property: string, content: string): HTMLMetaElement {
    const tag = document.createElement('meta')
    tag.setAttribute('property', property)
    tag.content = content
    return tag
  }

  public static createMetaNameTag(name: string, content: string): HTMLMetaElement {
    const tag = document.createElement('meta')
    tag.name = name
    tag.content = content
    return tag
  }

  public static createLinkTag(rel: string, href: string): HTMLLinkElement {
    const tag = document.createElement('link')
    tag.setAttribute('rel', rel)
    tag.setAttribute('href', href)
    return tag
  }

  public static crawlerMetaTag() {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height', 'fb:app_id']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'keywords', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      if (process.env.VUE_APP_SEO === 'true') {
        const title = 'LITEVIEW（ライトビュー） | ノーコード自社アプリ・Webサイト制作ツール'
        const description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」は、会員やコミュニティメンバー、従業員向けのコミュニケーションツールを自由に作成できるSaaSです。'
        const tags = [
          BrowserService.createMetaNameTag('description', description),
          BrowserService.createMetaTag('og:url', 'https://liteview.jp/'),
          BrowserService.createMetaTag('og:title', title),
          BrowserService.createMetaTag('og:description', description),
          BrowserService.createMetaTag('og:site_name', 'LITEVIEW'),
          BrowserService.createMetaTag('og:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png'),
          BrowserService.createMetaTag('og:image:width', '1200'),
          BrowserService.createMetaTag('og:image:height', '630'),
          BrowserService.createMetaTag('fb:app_id', '1162394754193649'),
          BrowserService.createMetaNameTag('keywords', 'クリエイター,ライブ配信,動画配信,ブログ,オンライン授業,オンラインサロン,ECサイト,コンテンツ配信'),
          BrowserService.createMetaNameTag('twitter:card', 'summary'),
          BrowserService.createMetaNameTag('twitter:title', title),
          BrowserService.createMetaNameTag('twitter:description', description),
          BrowserService.createMetaNameTag('twitter:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png')
        ]
        document.head.append(...tags)
      }
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static metaTag(template?: any, disable = false, customDomain = false) {
    const useIndex = !template || !template.noIndex
    app.$log?.info(`seo enable: ${disable ? 'false' : 'true'}, useIndex: ${useIndex}`)
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height', 'fb:app_id']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'keywords', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      document.querySelectorAll('link[rel="canonical"]')
        .forEach(tag => tag.remove())
      const isAdult = template && !!template.ageRating && template.ageRating >= 18
      if (!template) {
        document.title = 'LITEVIEW（ライトビュー） | ノーコード自社アプリ・Webサイト制作ツール'
        document.querySelector('#liteview-manifest-placeholder')?.setAttribute('href', '/site.webmanifest')
      } else if (customDomain) {
        document.querySelector('#liteview-manifest-placeholder')?.setAttribute('href', '/site.webmanifest')
      } else {
        document.querySelector('#liteview-manifest-placeholder')?.setAttribute('href', `${process.env.VUE_APP_API_HOST}share/site.webmanifest?subd=${encodeURIComponent(template.subdomain)}`)
      }
      if (process.env.VUE_APP_SEO === 'true' && !disable && !isAdult && useIndex) {
        if (template) {
          const tags = [
            BrowserService.createMetaTag('og:url', `https://liteview.jp/${template.subdomain}`),
            BrowserService.createMetaTag('og:title', template.name),
            BrowserService.createMetaTag('og:site_name', template.name),
            BrowserService.createMetaTag('fb:app_id', '1162394754193649'),
            BrowserService.createMetaNameTag('twitter:card', 'summary'),
            BrowserService.createMetaNameTag('twitter:title', template.name)
          ]
          if (template.useCustomDescription) {
            if (template.customDescription) {
              tags.push(BrowserService.createMetaTag('og:description', template.customDescription))
              tags.push(BrowserService.createMetaNameTag('description', template.customDescription))
            }
          } else if (template.description) {
            tags.push(BrowserService.createMetaTag('og:description', template.description))
            tags.push(BrowserService.createMetaNameTag('description', template.description))
          }
          if (template.logo && template.logo.type === 1 && template.logo.image &&
            template.logo.image.uploaded &&
            template.logo.image.width && template.logo.image.height &&
            Math.max(template.logo.image.width, template.logo.image.height) >= 200) {
            tags.push(...[
              BrowserService.createMetaTag('og:image', template.logo.image.fileURL),
              BrowserService.createMetaTag('og:image:width', template.logo.image.width),
              BrowserService.createMetaTag('og:image:height', template.logo.image.height),
              BrowserService.createMetaNameTag('twitter:image', template.logo.image.fileURL)
            ])
          } else {
            let backgroundImageURL: string | null = null
            try {
              const templateData = SiteDesignData.from(template.content || '{}')
              for (const section of templateData.sections) {
                if (section.type === SiteDesignSectionType.Top3) {
                  break
                } else if (section.type === SiteDesignSectionType.Top) {
                  for (const d of section.data) {
                    if (d.type === SiteDesignSectionDataType.BackgroundImage) {
                      backgroundImageURL = d.data as string
                    } else if (d.type === SiteDesignSectionDataType.SlideImage) {
                      const slideImages = d.data as string[]
                      if (slideImages.length > 0) {
                        backgroundImageURL = slideImages[0]
                      }
                    }
                  }
                }
              }
            } catch (e) {
              console.error(e)
            }
            if (backgroundImageURL && backgroundImageURL.startsWith('https://')) {
              tags.push(...[
                BrowserService.createMetaTag('og:image', backgroundImageURL),
                BrowserService.createMetaNameTag('twitter:image', backgroundImageURL)
              ])
            } else if (template.icon && template.icon.uploaded) {
              tags.push(...[
                BrowserService.createMetaTag('og:image', template.icon.fileURL),
                BrowserService.createMetaTag('og:image:width', template.icon.width),
                BrowserService.createMetaTag('og:image:height', template.icon.height),
                BrowserService.createMetaNameTag('twitter:image', template.icon.fileURL)
              ])
            }
          }
          document.head.append(...tags)
        } else {
          const title = 'LITEVIEW（ライトビュー） | ノーコード自社アプリ・Webサイト制作ツール'
          const description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」は、会員やコミュニティメンバー、従業員向けのコミュニケーションツールを自由に作成できるSaaSです。'
          const tags = [
            BrowserService.createMetaNameTag('description', description),
            BrowserService.createMetaTag('og:url', 'https://liteview.jp/'),
            BrowserService.createMetaTag('og:title', title),
            BrowserService.createMetaTag('og:description', description),
            BrowserService.createMetaTag('og:site_name', 'LITEVIEW for CREATOR'),
            BrowserService.createMetaTag('og:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png'),
            BrowserService.createMetaTag('og:image:width', '1200'),
            BrowserService.createMetaTag('og:image:height', '630'),
            BrowserService.createMetaTag('fb:app_id', '1162394754193649'),
            BrowserService.createMetaNameTag('keywords', 'クリエイター,ライブ配信,動画配信,ブログ,オンライン授業,オンラインサロン,ECサイト,コンテンツ配信'),
            BrowserService.createMetaNameTag('twitter:card', 'summary'),
            BrowserService.createMetaNameTag('twitter:title', title),
            BrowserService.createMetaNameTag('twitter:description', description),
            BrowserService.createMetaNameTag('twitter:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png')
          ]
          document.head.append(...tags)
        }
      } else {
        const tag1 = document.createElement('meta')
        tag1.name = 'robots'
        tag1.content = 'noindex'
        document.head.appendChild(tag1)
        const tag2 = document.createElement('meta')
        tag2.name = 'googlebot'
        tag2.content = 'noindex'
        document.head.appendChild(tag2)
        const tag3 = document.createElement('meta')
        tag3.name = 'robots'
        tag3.content = 'nofollow'
        document.head.appendChild(tag3)
        const tag4 = document.createElement('meta')
        tag4.name = 'googlebot'
        tag4.content = 'nofollow'
        document.head.appendChild(tag4)
      }
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static favicon(template?: any) {
    if (isFaviconSet) {
      return
    }
    isFaviconSet = true
    try {
      document.querySelectorAll('[rel="apple-touch-icon"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      document.querySelectorAll('[rel="apple-touch-icon-precomposed"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      document.querySelectorAll('[rel="icon"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      document.querySelectorAll('[rel="mask-icon"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      document.querySelectorAll('[name="msapplication-TileImage"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      document.querySelectorAll('[data-id="favicon"]').forEach(tag => {
        document.head.removeChild(tag)
      })
      if (template && template.favicon) {
        const ico = document.createElement('link')
        ico.setAttribute('data-id', 'favicon')
        ico.setAttribute('rel', 'shortcut icon')
        ico.setAttribute('type', 'image/x-icon')
        ico.setAttribute('href', template.favicon.ico.fileURL)
        document.head.appendChild(ico)
        const png = document.createElement('link')
        png.setAttribute('data-id', 'favicon')
        png.setAttribute('rel', 'apple-touch-icon')
        png.setAttribute('sizes', '180x180')
        png.setAttribute('href', template.favicon.png.fileURL)
        document.head.appendChild(png)
        const png2 = document.createElement('link')
        png2.setAttribute('data-id', 'favicon')
        png2.setAttribute('rel', 'apple-touch-icon-precomposed')
        png2.setAttribute('sizes', '180x180')
        png2.setAttribute('href', template.favicon.png.fileURL)
        document.head.appendChild(png2)
        const colors = template.favicon.maskColor.toLowerCase().match(/^([0-9a-fA-F]{2})([0-9a-f]{2})([0-9a-f]{2})/)
        const mask = document.createElement('link')
        mask.setAttribute('data-id', 'favicon')
        mask.setAttribute('rel', 'mask-icon')
        mask.setAttribute('color', colors ? `#${colors[1]}${colors[2]}${colors[3]}` : '#000000')
        mask.setAttribute('href', template.favicon.mask.fileURL)
        document.head.appendChild(mask)
      } else {
        const ico = document.createElement('link')
        ico.setAttribute('data-id', 'favicon')
        ico.setAttribute('rel', 'shortcut icon')
        ico.setAttribute('type', 'image/x-icon')
        ico.setAttribute('href', './img/icons/favicon.ico')
        document.head.appendChild(ico)
        const png = document.createElement('link')
        png.setAttribute('data-id', 'favicon')
        png.setAttribute('rel', 'apple-touch-icon')
        png.setAttribute('sizes', '180x180')
        png.setAttribute('href', './img/icons/apple-touch-icon.png')
        document.head.appendChild(png)
        const png2 = document.createElement('link')
        png2.setAttribute('data-id', 'favicon')
        png2.setAttribute('rel', 'apple-touch-icon-precomposed')
        png2.setAttribute('sizes', '180x180')
        png2.setAttribute('href', './img/icons/apple-touch-icon.png')
        document.head.appendChild(png2)
        const mask = document.createElement('link')
        mask.setAttribute('data-id', 'favicon')
        mask.setAttribute('rel', 'mask-icon')
        mask.setAttribute('href', './img/icons/safari-pinned-tab.svg')
        mask.setAttribute('color', '#000000')
        document.head.appendChild(mask)
      }
    } catch (e) {
      console.error(e)
    }
  }

  public static userInterviewMetaTag(interview: any, fromBiz: boolean) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      document.querySelectorAll('link[rel="canonical"]')
        .forEach(tag => tag.remove())
      const toCUrl = window.location.origin + getBaseUrl() + `creator/interview/${interview.uid}`
      const toBUrl = window.location.origin + getBaseUrl() + `business/case/${interview.uid}`
      const title = `LITEVIEW（ライトビュー）の活用事例 | ${interview.title}`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', fromBiz ? toBUrl : toCUrl),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ] as HTMLElement[]
      const tmp = document.createElement('DIV')
      tmp.innerHTML = interview.description || ''
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (interview.image && interview.image.fileURL) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', interview.image.fileURL),
          BrowserService.createMetaNameTag('twitter:image', interview.image.fileURL)
        ])
        if (interview.image.width && interview.image.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', interview.image.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', interview.image.height.toString(10))
          ])
        }
      }
      if (interview.canonical === 2) {
        if (!fromBiz) {
          tags.push(BrowserService.createLinkTag('canonical', toBUrl))
        }
      } else if (interview.canonical === 1) {
        if (fromBiz) {
          tags.push(BrowserService.createLinkTag('canonical', toCUrl))
        }
      }
      if (process.env.VUE_APP_SEO !== 'true' || !interview.description) {
        const tag1 = document.createElement('meta')
        tag1.name = 'robots'
        tag1.content = 'noindex'
        document.head.appendChild(tag1)
        const tag2 = document.createElement('meta')
        tag2.name = 'googlebot'
        tag2.content = 'noindex'
        document.head.appendChild(tag2)
        const tag3 = document.createElement('meta')
        tag3.name = 'robots'
        tag3.content = 'nofollow'
        document.head.appendChild(tag3)
        const tag4 = document.createElement('meta')
        tag4.name = 'googlebot'
        tag4.content = 'nofollow'
        document.head.appendChild(tag4)
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static functionMetaTag(data: any) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      document.querySelectorAll('link[rel="canonical"]')
        .forEach(tag => tag.remove())
      const url = window.location.origin + getBaseUrl() + `creator/function/${data.uid}`
      const title = `${data.title} | LITEVIEW`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ]
      const tmp = document.createElement('DIV')
      tmp.innerHTML = data.description || ''
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (data.image && data.image.fileURL) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', data.image.fileURL),
          BrowserService.createMetaNameTag('twitter:image', data.image.fileURL)
        ])
        if (data.image.width && data.image.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', data.image.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', data.image.height.toString(10))
          ])
        }
      }
      if (process.env.VUE_APP_SEO !== 'true' || !data.description) {
        const tag1 = document.createElement('meta')
        tag1.name = 'robots'
        tag1.content = 'noindex'
        document.head.appendChild(tag1)
        const tag2 = document.createElement('meta')
        tag2.name = 'googlebot'
        tag2.content = 'noindex'
        document.head.appendChild(tag2)
        const tag3 = document.createElement('meta')
        tag3.name = 'robots'
        tag3.content = 'nofollow'
        document.head.appendChild(tag3)
        const tag4 = document.createElement('meta')
        tag4.name = 'googlebot'
        tag4.content = 'nofollow'
        document.head.appendChild(tag4)
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static creatorMetaTag() {
    try {
      document.querySelector('#liteview-manifest-placeholder')?.setAttribute('href', '/site.webmanifest')
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height', 'fb:app_id']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'keywords', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      document.querySelectorAll('link[rel="canonical"]')
        .forEach(tag => tag.remove())
      const url = window.location.origin + getBaseUrl() + 'creator'
      const title = 'LITEVIEW for CREATOR｜個人クリエイターの専用アプリとWebサイトをご提供'
      document.title = title
      const description = '初期費用、手数料0円、個人クリエイターのビジネスを支える専用アプリとWebサイトをノーコードで気軽に。会員限定コンテンツ配信（動画、ライブ配信など）・コミュニティ、オンラインスクール、オンラインサロン、EC、クラファンなどのビジネスを簡単に構築可能！'
      const tags = [
        BrowserService.createMetaNameTag('description', description),
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:description', description),
        BrowserService.createMetaTag('og:site_name', 'LITEVIEW for CREATOR'),
        BrowserService.createMetaTag('og:image', 'https://liteview.jp/static/liteview-ogp3.png'),
        BrowserService.createMetaTag('og:image:width', '1200'),
        BrowserService.createMetaTag('og:image:height', '630'),
        BrowserService.createMetaTag('fb:app_id', '1162394754193649'),
        BrowserService.createMetaNameTag('keywords', 'クリエイター,ライブ配信,動画配信,ブログ,オンライン授業,オンラインサロン,ECサイト,コンテンツ配信'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title),
        BrowserService.createMetaNameTag('twitter:description', description),
        BrowserService.createMetaNameTag('twitter:image', 'https://liteview.jp/static/liteview-ogp3.png')
      ]
      if (process.env.VUE_APP_SEO !== 'true') {
        const tag1 = document.createElement('meta')
        tag1.name = 'robots'
        tag1.content = 'noindex'
        document.head.appendChild(tag1)
        const tag2 = document.createElement('meta')
        tag2.name = 'googlebot'
        tag2.content = 'noindex'
        document.head.appendChild(tag2)
        const tag3 = document.createElement('meta')
        tag3.name = 'robots'
        tag3.content = 'nofollow'
        document.head.appendChild(tag3)
        const tag4 = document.createElement('meta')
        tag4.name = 'googlebot'
        tag4.content = 'nofollow'
        document.head.appendChild(tag4)
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static businessMetaTag(type: 'main' | 'function' | 'case' | 'plan' | 'consulting' | 'team', route?: string) {
    try {
      document.querySelector('#liteview-manifest-placeholder')?.setAttribute('href', '/site.webmanifest')
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height', 'fb:app_id']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'keywords', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      document.querySelectorAll('link[rel="canonical"]')
        .forEach(tag => tag.remove())
      const url = window.location.origin + getBaseUrl() + (route ?? '')
      let title = 'LITEVIEW（ライトビュー） | ノーコード自社アプリ・Webサイト制作ツール'
      let description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」は、会員やコミュニティメンバー、従業員向けのコミュニケーションツールを自由に作成できるSaaSです。'
      if (type === 'function') {
        title = 'LITEVIEW（ライトビュー）の機能 | ノーコード自社アプリ・Webサイト制作ツール'
        description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」の機能やできることがわかるページです。LITEVIEWは動画やブログ記事の配信、掲示板、チャット、アンケートなど、豊富な機能をノーコードで実現できます。'
      } else if (type === 'case') {
        title = 'LITEVIEW（ライトビュー）の活用事例 | ノーコード自社アプリ・Webサイト制作ツール'
        description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」の活用事例インタビューをまとめたページです。'
      } else if (type === 'plan') {
        title = 'LITEVIEW（ライトビュー）の料金プラン | ノーコード自社アプリ・Webサイト制作ツール'
        description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」の料金プラン・価格がわかるページです。LITEVIEWは業界最安水準の月額70,000円(税抜)からご利用いただけます。'
      } else if (type === 'consulting') {
        title = 'LITEVIEW（ライトビュー）の無料オンライン相談 | ノーコード自社アプリ・Webサイト制作ツール'
        description = 'ノーコード自社アプリ・Webサイト制作ツール「LITEVIEW（ライトビュー）」について、すぐに30分の無料オンライン予約ができます。お見積りや活用方法、導入手順、サポート体制などについてお気軽にご相談ください。'
      } else if (type === 'team') {
        title = 'LITEVIEW for TEAM | ノーコードで作る理想の社内ポータル・社内報アプリ x 社内DX'
        description = 'ノーコード社内報アプリ制作ツール「LITEVIEW for TEAM（ライトビューフォーチーム）」は、企業が従業員向けの社内報アプリ・Web社内報をノーコードで自由に制作できるツールです。'
      }
      document.title = title
      const tags = [
        BrowserService.createMetaNameTag('description', description),
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:description', description),
        BrowserService.createMetaTag('og:site_name', 'LITEVIEW'),
        BrowserService.createMetaTag('og:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png'),
        BrowserService.createMetaTag('og:image:width', '1200'),
        BrowserService.createMetaTag('og:image:height', '630'),
        BrowserService.createMetaTag('fb:app_id', '1162394754193649'),
        BrowserService.createMetaNameTag('keywords', 'クリエイター,ライブ配信,動画配信,ブログ,オンライン授業,オンラインサロン,ECサイト,コンテンツ配信'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title),
        BrowserService.createMetaNameTag('twitter:description', description),
        BrowserService.createMetaNameTag('twitter:image', 'https://liteview.jp/static/assets/corporate-biz/ogp.png')
      ]
      if (process.env.VUE_APP_SEO !== 'true') {
        const tag1 = document.createElement('meta')
        tag1.name = 'robots'
        tag1.content = 'noindex'
        document.head.appendChild(tag1)
        const tag2 = document.createElement('meta')
        tag2.name = 'googlebot'
        tag2.content = 'noindex'
        document.head.appendChild(tag2)
        const tag3 = document.createElement('meta')
        tag3.name = 'robots'
        tag3.content = 'nofollow'
        document.head.appendChild(tag3)
        const tag4 = document.createElement('meta')
        tag4.name = 'googlebot'
        tag4.content = 'nofollow'
        document.head.appendChild(tag4)
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static isCustomDomain(): boolean {
    const customDomain = (app.$store?.state as State)?.meta?.customDomain
    return !!customDomain && customDomain !== window.location.hostname.toLowerCase()
  }

  public static jumpToPathOnMyPage(path: string,
    query?: string,
    noLogin?: boolean,
    forceDomain?: string,
    replace?: boolean) {
    const currentPath = (app.$route?.path) || '/'
    if (currentPath && !currentPath.startsWith('/member') && !currentPath.startsWith('/order')) {
      BrowserService.jumpToPathOnRouter(path, query, replace)
    } else {
      BrowserService.jumpToPath(path, query, noLogin, forceDomain, replace)
    }
  }

  public static jumpToPathOnRouter(path: string, query?: string, replace?: boolean) {
    const queryMap: Dictionary<string | (string | null)[] | null | undefined> = {}
    if (query) {
      const params = query.split('&')
      if (params) {
        for (const q of params) {
          const arr = q.split('=')
          if (arr && arr.length >= 2) {
            queryMap[arr[0]] = arr[1]
          }
        }
      }
    }
    if (replace) {
      app.$router?.replace({
        path,
        query: queryMap
      })
    } else {
      app.$router?.push({
        path,
        query: queryMap
      })
    }
  }

  public static jumpToPath(path: string, query?: string, noLogin?: boolean, forceDomain?: string, replace?: boolean) {
    const domain = (app.$store?.state as State)?.meta?.subdomain
    if (forceDomain || BrowserService.isCustomDomain()) {
      let url = `${process.env.VUE_APP_API_HOST}subscription/web/user/service/link?d=${encodeURIComponent(forceDomain || domain)}&p=${encodeURIComponent(path)}`
      if (query) {
        url += `&q=${encodeURIComponent(query)}`
      }
      if (noLogin !== undefined) {
        url += `&noLogin=${noLogin ? '1' : '0'}`
      }
      window.location.href = url
    } else {
      BrowserService.jumpToPathOnRouter(path, query, replace)
    }
  }

  public static jumpToLiteviewPath(path: string, query?: string, replace?: boolean) {
    const hostname = window.location.hostname.toLowerCase()
    if (hostname && hostname !== process.env.VUE_APP_MAIN_DOMAIN && hostname !== 'localhost') {
      const domain = (app.$store?.state as State)?.meta?.subdomain
      let url = `${process.env.VUE_APP_API_HOST}subscription/web/user/service/link?d=${encodeURIComponent(domain)}&p=${encodeURIComponent(path)}&not=1`
      if (query) {
        url += `&q=${encodeURIComponent(query)}`
      }
      window.location.href = url
    } else {
      BrowserService.jumpToPathOnRouter(path, query, replace)
    }
  }

  public static openUrl(url: string) {
    if (BrowserService.isMobileDevice()) {
      window.location.href = url
    } else {
      window.open(url)
    }
  }

  public static copyToClipboard(text: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowAny = window as any
    if (windowAny.clipboardData && windowAny.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      windowAny.clipboardData.setData('Text', text)
      return true
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea')
      textarea.textContent = text
      textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand('copy') // Security exception may be thrown by some browsers.
        return true
      } catch (ex) {
        app.$log?.error('Copy to clipboard failed.', ex)
        return false
      } finally {
        document.body.removeChild(textarea)
      }
    }
    return false
  }
}
