import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import router from '@/router'
import app from '@/main'

const CONFIG = {
  isEnabled: true,
  gtagScriptExists: false,
  loadPromise: Promise.resolve() as Promise<void>,
  gtmEnabled: false
}

export class GTAGService {
  static init() {
    if (CONFIG.isEnabled) {
      const gaId = process.env.VUE_APP_GA_ID
      if (gaId) {
        CONFIG.loadPromise = GTAGService._load(gaId)
      } else {
        CONFIG.loadPromise = Promise.resolve()
      }
    }
  }

  static isEnabled(): boolean {
    return CONFIG.isEnabled
  }

  static load(gtagId?: string, subdomain?: string) {
    if (CONFIG.isEnabled) {
      const gtmIdList: string[] = []
      const gtmId = process.env.VUE_APP_GTM_ID
      if (gtmId) {
        gtmIdList.push(gtmId)
      }
      if (gtagId) {
        if (gtagId !== 'GTM-KHJ2M2D' && (gtagId === 'G-JKBCY77KSZ' || (subdomain && subdomain === 'poquito'))) {
          gtmIdList.push('GTM-KHJ2M2D')
        }
        if (gtagId.startsWith('GTM-')) {
          gtmIdList.push(gtagId)
        } else {
          CONFIG.loadPromise = CONFIG.loadPromise
            .catch(() => Promise.resolve())
            .then(() => GTAGService._load(gtagId))
        }
      }
      if (!CONFIG.gtmEnabled && gtmIdList.length > 0) {
        GTAGService._loadGTM(gtmIdList)
      }
    }
  }

  private static _loadGTM(gtmId: string[]) {
    CONFIG.gtmEnabled = true
    Vue.use(VueGtm, {
      id: gtmId,
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: false, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: [], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
    try {
      let hash = window.location.hash
      if (hash) {
        if (hash.startsWith('#')) {
          hash = hash.substr(1)
        }
      }
      if (!hash) {
        hash = '/'
      }
      app.$gtm?.trackEvent({
        event: 'gtm.historyChange-v2',
        'gtm.oldUrlFragment': null,
        'gtm.newUrlFragment': hash
      })
    } catch (e) {
      console.error(e)
    }
  }

  private static _load(gtagId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const windowAny = window as any
      // Setting dataLayer & gtag to window because I'm using a custom code text field in a tag management system
      windowAny.dataLayer = windowAny.dataLayer || []
      windowAny.gtag =
        windowAny.gtag ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          windowAny.dataLayer.push(arguments)
        }
      windowAny.gtag('js', new Date())

      windowAny.gtag('config', gtagId)

      // NOTE: gtag script only needs to be set to page once, but each gtag('config', <ID>) that's pushed to the dataLayer will add subsequent gtag/js?id=<ID> scripts to the page
      if (!CONFIG.gtagScriptExists) {
        // Set initial gtag/js?id=<first ID> script to <head>
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = '//www.googletagmanager.com/gtag/js?id=' + gtagId
        document.getElementsByTagName('head')[0].appendChild(script)
        script.onload = () => {
          // Update gtag/js?id= script status flag so this initialization script is only set for the first ID, and not all the IDs in the array
          CONFIG.gtagScriptExists = true
          resolve()
        }
        script.onerror = (e) => {
          console.error(e)
          reject(e)
        }
      } else {
        resolve()
      }
    })
  }
}
