import localForage from 'localforage'
import app from '@/main'

function fallbackStorage(): string {
  if (window.indexedDB) {
    return localForage.INDEXEDDB
  }
  return localForage.LOCALSTORAGE
}

try {
  localForage.config({
    driver: fallbackStorage(), // Force WebSQL; same as using setDriver()
    name: 'LITEVIEW-PC',
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'liteview_pc_store', // Should be alphanumeric, with underscores.
    description: 'liteview_pc'
  })
} catch (e) {
  app.$log.error(e)
}

export class PlayerStoreService {
  store: LocalForage

  constructor(namespace: string, useLocalStorage: boolean) {
    this.store = localForage.createInstance({
      driver: useLocalStorage ? localForage.LOCALSTORAGE : fallbackStorage(),
      name: namespace
    })
  }

  public static create(namespace: string, useLocalStorage = false): PlayerStoreService {
    return new PlayerStoreService(namespace, useLocalStorage)
  }

  public static default(): LocalForage {
    return localForage
  }

  public setItem<T>(key: string, value: T): Promise<T> {
    return this.store.setItem(key, value)
  }

  public getItem<T>(key: string): Promise<T | null> {
    return this.store.getItem(key)
  }

  public removeItem(key: string): Promise<void> {
    return this.store.removeItem(key)
  }
}
