





























import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import { Proto } from '@/player/model/proto'
import ContactEmailModal from '@/player/components/modal/ContactEmailModal.vue'
import { PlayerUIService } from '@/player/service/ui.service'
import { State } from '@/store'

@Component
export default class ContactModal extends AbstractModalVue {
  public static NAME = 'contact-modal'

  @Prop() planName!: string
  @Prop() companyUID!: string
  @Prop() content!: Proto.IContent
  @Prop() notice!: boolean

  created() {
    this.contact()
  }

  install(event: Event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.content) {
      window.open(PlayerUIService.appUrl(this.companyUID as string, this.content.playlistUID as string,
        this.content.uid as string, this.content.type as number), '_blank')
    } else {
      window.open(PlayerUIService.appUrl(this.companyUID as string), '_blank')
    }
  }

  contact(event?: Event) {
    event?.preventDefault()
    event?.stopPropagation()

    this.hide()
    const name = ContactEmailModal.NAME + '-' + Math.random()
    this.showModal(name, ContactEmailModal, {
      planName: this.planName,
      content: this.content ? this.content : null
    }, {
      clickToClose: false
    })
  }

  get appName(): string {
    return (this.$store.state as State).playerMeta.appName
  }

  hide() {
    super.hide()
  }
}
