














import { Component, Prop } from 'vue-property-decorator'
import { MessageError } from '@/player/model/error'
import { AbstractModalVue } from '@/views/AbstractModalVue'

@Component
export default class AlertModal extends AbstractModalVue {
  public static NAME = 'alert-modal'

  @Prop() private title!: string | undefined
  @Prop() private message!: string | undefined
  @Prop() private error!: Error | undefined

  private errMsg = ''

  beforeMount() {
    if (this.error) {
      if (this.error instanceof MessageError) {
        this.errMsg = (this.error as MessageError).getMessage()
      } else {
        this.errMsg = this.error.message
      }
    } else {
      this.errMsg = this.message ?? ''
    }
  }

  hide() {
    super.hide()
  }
}
