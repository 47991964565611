export const DefaultAppName = 'LITEVIEW'
export const DefaultAppIcon = 'img/icons/apple-touch-icon.png'

export enum ServerType {
  None = 0,
  Local = 2,
  S3File = 1001
}

export const AWSConstant = {
  AWS_CREDENTIAL_EXPIRE_LIMIT_SECONDS: 120,

  AWS_UPLOAD_PART_SIZE: 5 * 1024 * 1024,
  AWS_UPLOAD_QUEUE_SIZE: 4,

  DEFAULT_TAGS: process.env.NODE_ENV === 'production' ? [{
    Key: 'Company',
    Value: 'LITEVIEW'
  }, {
    Key: 'Service',
    Value: 'liteview'
  }] : [{
    Key: 'Company',
    Value: 'LITEVIEW'
  }, {
    Key: 'Service',
    Value: 'liteview'
  }, {
    Key: 'Development',
    Value: 'true'
  }]
}

export enum FileType {
  pdf = 0,
  img = 10,
  music = 20,
  movie = 30,
  other = 90
}

export interface ProgressData<T> {
  progress: number;
  end: boolean;
  response?: T;
}

export const corsURL = (url: string): string => {
  if (url.startsWith('data:')) {
    return url
  }
  const uri = new URL(url)
  if (uri.hostname === 'localhost' || uri.hostname === '127.0.0.1') {
    return url
  }
  const current = new URL(window.location.href)
  if ((!uri.protocol || uri.protocol === current.protocol) && uri.host === current.host) {
    return url
  }
  return new URL('/corsfile', process.env.VUE_APP_API_HOST).toString() + '?url=' + encodeURIComponent(url)
}

const removeSubdomainFrom = (fullUrl: string) => {
  // configure host strings
  const regex = {
    protocol: new RegExp(/http(s)*:\/\//), // gets the http:// OR https:// from url string
    subdomain: new RegExp(/^[^.]*\.(?=\w+\.\w+$)/) // gets the http(s)://subdomain portion from url string
  }
  let newUrl = fullUrl
  const protocol = regex.protocol.exec(fullUrl) // save protocol from provided Url so we can reapply it to the non-subdomain
  if (protocol && protocol.length) {
    // if https://subdomain exists, just remove the subdomain from it
    newUrl = fullUrl.replace(regex.subdomain, protocol[0])
  }
  return newUrl
}

export const isAPIHost = (url: string): boolean => {
  const current = new URL(process.env.VUE_APP_API_HOST ?? '')
  const uri = new URL(url)
  return (!uri.protocol || uri.protocol === current.protocol) &&
    (removeSubdomainFrom(uri.toString()) === removeSubdomainFrom(current.toString()))
}

export const isString = (str: unknown): boolean => {
  return Object.prototype.toString.call(str) === '[object String]'
}

export const spamMailList = [
  'ezweb.ne.jp',
  'biz.ezweb.ne.jp',
  'augps.ezweb.ne.jp',
  'ido.ne.jp',
  'uqmobile.jp',
  'au.com',
  'biz.au.com',
  'docomo.ne.jp',
  'mopera.net',
  'disney.ne.jp',
  'disneymobile.ne.jp',
  'i.softbank.jp',
  'softbank.ne.jp',
  'vodafone.ne.jp',
  'emnet.ne.jp',
  'emobile.ne.jp',
  'emobile-s.ne.jp',
  'ymobile1.ne.jp',
  'ymobile.ne.jp',
  'yahoo.ne.jp',
  'pdx.ne.jp',
  'willcom.com',
  'wcm.ne.jp',
  'y-mobile.ne.jp',
  'jp-c.ne.jp',
  'jp-d.ne.jp',
  'jp-h.ne.jp',
  'jp-k.ne.jp',
  'jp-n.ne.jp',
  'jp-q.ne.jp',
  'jp-r.ne.jp',
  'jp-s.ne.jp',
  'jp-t.ne.jp',
  'sky.tkc.ne.jp',
  'sky.tkk.ne.jp',
  'sky.tu-ka.ne.jp'
]

export interface ProgressResult<T> {
  progress?: number;
  data?: T;
}

export const CONTENT_NEW_TIME_IN_MS = 1000 * 60 * 60 * 36
export const TAX_FEE = 0.1

export const FormImageFileAccept = 'image/png, image/jpeg'

export const NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 10
}
