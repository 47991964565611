import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index'
import './plugin/logger'
import './plugin/progressbar'
import './plugin/modal'
import './plugin/scrollto'
import './plugin/observeVisiblity'
import './plugin/bootstrap'
import './plugin/sanitize'

import wb from './registerServiceWorker'
import { GTAGService } from '@/service/gtag.service'
import { Component } from 'vue-property-decorator'

Vue.prototype.$workbox = wb

Vue.config.productionTip = false

GTAGService.init()

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app
