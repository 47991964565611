/* eslint-disable @typescript-eslint/no-explicit-any */
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentity } from '@aws-sdk/credential-provider-cognito-identity'
import { PutObjectCommand, PutObjectTaggingCommand, S3Client, Tag } from '@aws-sdk/client-s3'
import { Proto } from '@/player/model/proto'
import PlayerHttp from '@/player/service/api'
import { MessageError } from '@/player/model/error'
import { PlayerStoreService } from '@/player/service/store.service'
import { StoreKey } from '@/player/model/constant'
import { isString } from '@/model/constant'
import Http from '@/service/api'
import axios, { AxiosRequestConfig } from 'axios'
import app from '@/main'
import { State } from '@/store'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
declare let moment: typeof moment

export class AWSService {
  private static instance: AWSService

  private _poolData!: Proto.AWSCognitoPool
  private _store = PlayerStoreService.default()
  private _s3Client: { [key: string]: [S3Client, Proto.AWSCognito] } = {}

  private constructor() {
  }

  public static getInstance(): AWSService {
    return this.instance || (this.instance = new this())
  }

  private _identityPool(): Promise<Proto.AWSCognitoPool> {
    if (this._poolData) {
      return Promise.resolve(this._poolData)
    }
    return PlayerHttp.instance().requestAPIProto('aws/identity-pool',
      {
        method: 'GET'
      })
      .then(res => {
        if (res.data) {
          const data = Proto.AWSCognitoPool.decode(res.data, res.data.length)
          this._poolData = data
          return data
        }
        throw MessageError.Unexpected
      })
  }

  private _identity(uuid: string): Promise<Proto.AWSCognito> {
    const key = StoreKey.AWS_IDENTITY + uuid
    return this._store.getItem(key)
      .catch(e => {
        app.$log?.error(e)
        return Promise.resolve(null)
      })
      .then((dataJson: any) => {
        if (dataJson !== null) {
          const data = Proto.AWSCognito.fromObject(dataJson)
          if (data.expire - new Date().getTime() >= 120 * 1000) {
            return data
          }
        }
        return PlayerHttp.instance().requestAPIProto('aws/identity',
          {
            method: 'GET'
          })
          .then(res => {
            if (res.data) {
              const data = Proto.AWSCognito.decode(res.data, res.data.length)
              this._store.setItem(key, data.toJSON())
                .catch(e => {
                  app.$log?.error(e)
                })
              return data
            }
            throw MessageError.Unexpected
          })
      })
  }

  s3Client(uuid: string): Promise<[S3Client, Proto.AWSCognito]> {
    const map = this._s3Client[uuid]
    if (map) {
      if (map[1].expire - new Date().getTime() >= 120 * 1000) {
        return Promise.resolve(map)
      } else {
        map[0].destroy()
      }
      delete this._s3Client[uuid]
    }
    return this._identity(uuid)
      .then(data => {
        const credentials = fromCognitoIdentity({
          client: new CognitoIdentityClient({ region: data.region }),
          identityId: data.identityId,
          logins: {
            'cognito-identity.amazonaws.com': data.token
          }
        })
        const s3 = new S3Client({
          region: data.region,
          credentials
        })
        this._s3Client[uuid] = [s3, data]
        return [s3, data]
      })
  }

  private _clientIpCache: [string, moment.Moment] | null = null

  private _clientIp(): Promise<string> {
    if (this._clientIpCache && this._clientIpCache[1].diff(moment(), 'second') >= -60 * 10) {
      return Promise.resolve(this._clientIpCache[0])
    }
    return axios({
      url: 'https://www.cloudflare.com/cdn-cgi/trace',
      method: 'GET',
      responseType: 'text'
    })
      .then(response => {
        const responseValue = response.data as string
        const list = responseValue.trim().split('\n')
        let ip = '0.0.0.0'
        for (const keyValue of list) {
          const pair = keyValue.split('=')
          if (pair.length > 1 && pair[0] === 'ip') {
            ip = pair[1]
            app.$log?.debug(`log ip: ${ip}`)
            if (ip !== '0.0.0.0' && ip !== '0:0:0:0:0:0') {
              this._clientIpCache = [ip, moment()]
            }
            break
          }
        }
        return ip
      })
      .catch(e => {
        app.$log?.error(e)
        return Promise.resolve('0.0.0.0')
      })
  }

  private _createLogCSV(uuid: string, user: Proto.IUser, ip: string, headers: string[], data: any[]): string {
    const now = moment()
    let csv = '#version,date,time,userUID,userId,deviceUUID,viewerType,ip'
    if (headers.length > 0) {
      csv += ','
      csv += headers.join(',')
    }
    csv += '\n'
    csv += `2,"${now.format('YYYY-MM-DD')}","${now.format('HH:mm:ss')}",${user.uid},"${user.userId}","${uuid}",101,"${ip}"`
    csv += data.map(value => {
      if (isString(value)) {
        return `,"${value}"`
      } else {
        return `,${value}`
      }
    }).join('')
    return csv
  }

  addLog(uuid: string, user: Proto.IUser, path: string, headers: string[] = [], data: any[] = []): Promise<void> {
    if (!app.$store || (app.$store.state as State).meta?.subdomain !== 'dechannel') {
      return Promise.resolve()
    }
    return this.s3Client(uuid)
      .then(client => Promise.all([this._clientIp(), this._identityPool()])
        .then(results => {
          const poolData = results[1]
          const ip = results[0]
          const csv = this._createLogCSV(uuid, user, ip, headers, data)
          const now = moment()
          const prefix = poolData.pathPrefix.replace('{awsId}', client[1].identityId)
            .replace('{date}', now.format('YYYY-MM-DD'))
            .replace('{path}', path)
          const remotePath = `${prefix}${now.format('YYYY-MM-DDHHmmss')}.csv`
          const blob = new Blob([csv])

          if (app && app.$store && (app.$store.state as State).meta.isCustom) {
            const formData = new FormData()
            formData.append('file', blob)
            formData.append('path', remotePath)
            return Http.instance().requestAPIJson('subscription/web/user/user-log', {
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: formData
            } as AxiosRequestConfig)
              .then(res => {
              })
              .catch(e => {
                return Promise.reject(e)
              })
          }

          const command = new PutObjectCommand({
            Bucket: poolData.bucketName,
            Key: remotePath,
            ContentType: 'text/csv',
            ContentLength: blob.size,
            Body: blob
          })
          return client[0].send(command)
            .then(output => {
              const tagList: Tag[] = [{
                Key: 'Company',
                Value: 'LITEVIEW'
              }, {
                Key: 'Service',
                Value: 'liteviewer'
              }]
              if (process.env.VUE_APP_MAIN_DOMAIN !== 'liteview.jp') {
                tagList.push({
                  Key: 'Development',
                  Value: 'true'
                })
              }
              return client[0].send(new PutObjectTaggingCommand({
                Bucket: poolData.bucketName,
                Key: remotePath,
                Tagging: {
                  TagSet: tagList
                }
              }))
            })
            .then(() => {
            })
        })
      )
      .catch(e => {
        app.$log?.error(e)
      })
  }
}
