/* eslint-disable no-console,@typescript-eslint/no-unused-vars */
import { Workbox } from 'workbox-window'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { PlayerApiService } from '@/player/service/api.service'
import { PlayerStoreService } from '@/player/service/store.service'
import { StoreKey } from '@/player/model/constant'
import app from '@/main'
import { State } from '@/store'
import { notificationBusService } from '@/service/notification.service'
import { firebaseApp } from '@/service/firebase.service'

let isBot = false
try {
  isBot = /(Googlebot|bingbot|baiduspider|yandex|yeti|yodaobot|gigabot|ia_archiver|facebookexternalhit|twitterbot|Discordbot|SkypeUriPreview|PlurkBot|Slackbot|Twitterbot|Linespider|bot)/ig.test(navigator.userAgent)
} catch (e) {
  console.error(e)
}

let wb: Workbox
if (!isBot && process.env.VUE_APP_SERVICE_WORKER === '1') {
  if ('serviceWorker' in navigator) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let registration: any
    wb = new Workbox(`${process.env.BASE_URL}${process.env.VUE_APP_SERVICE_WORKER_PATH}`)

    const showSkipWaitingPrompt = () => {
      console.log('Service worker has been waiting.')
      // if (registration && registration.waiting) {
      //   messageSW(registration.waiting, { type: 'SKIP_WAITING' })
      // }
    }

    const observeMessage = () => {
      if (notificationBusService.getServiceWorkerOn()) {
        return
      }

      notificationBusService.setServiceWorkerOn(true)

      try {
        if (window.Notification && registration) {
          const firebase = firebaseApp()
          const messaging = getMessaging(firebase)

          const getTokenAndUpdate = (retry?: boolean) => {
            getToken(messaging, {
              vapidKey: 'BCoXJLtzjwRk4pphfg43R9bAvFzK5VNsygTXZlXUmIW04lA4-98x1kV4bD2T41MJdHw3lAIOgprP7HRbjqrB-lA',
              serviceWorkerRegistration: registration
            }).then((currentToken) => {
              if (currentToken) {
                notificationBusService.bus.$emit('permission-changed')
                // Send the token to your server and update the UI if necessary
                PlayerStoreService.default()
                  .setItem(StoreKey.DEVICE_TOKEN, currentToken)
                  .catch(e => {
                    console.error(e)
                  })
                if (app.$store) {
                  const uuid = (app.$store.state as State).playerAuth.uuid
                  const customDomain = (app.$store.state as State)?.meta?.customDomain
                  if (uuid && (!customDomain || customDomain === window.location.hostname.toLowerCase())) {
                    PlayerApiService.updateDeviceToken(uuid, currentToken)
                      .subscribe(updated => {
                        app.$log?.debug('updated token: ' + currentToken)
                      }, e => {
                        app.$log?.error(e)
                      })
                  }
                }
              }
            }).catch((err) => {
              app.$log?.debug(err)
              if (err.code === 'messaging/permission-blocked') {

              } else if (!retry) {
                getTokenAndUpdate(true)
              }
            })
          }

          notificationBusService.bus.$on('permission-granted', () => {
            getTokenAndUpdate()
          })

          const notificationPermission = Notification.permission
          if (notificationPermission === 'granted') {
            getTokenAndUpdate()
          }

          onMessage(messaging, (payload) => {
            app.$log?.debug('Message received. ', payload)

            if (!payload.data) {
              return
            }

            const notificationUID = payload.data.nuid || ''
            if (!notificationUID) {
              return
            }
            const notificationPermission = Notification.permission
            if (notificationPermission === 'granted') {
              const notificationTitle = payload.data.title || ''
              const notification = new Notification(notificationTitle, {
                body: payload.data.body,
                icon: payload.data.icon ? payload.data.icon : '/subscription/img/icons/android-chrome-512x512.png',
                image: payload.data.image
              })
              // const link = payload.fcmOptions?.link
              notification.onclick = () => {
                PlayerApiService.userNotificationOne(notificationUID)
                  .subscribe(data => {
                    const state = app.$store?.state as State
                    if (!state) {
                      return
                    }
                    notificationBusService.handleNotification(data, state, state.meta.board, app.$store?.getters.isCompanyOwner ?? false)
                  }, e => {
                    app.$log?.error(e)
                  })
              }
            }
          })
        }
      } catch (e) {
        console.error(e)
      }
    }

    let isRedundant = false
    wb.addEventListener('installed', () => {
      console.log('Service worker has been installed.')
    })
    wb.addEventListener('waiting', () => showSkipWaitingPrompt)
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt)
    wb.addEventListener('controlling', () => {
      console.log('Service worker has been controlling.')
    })
    wb.addEventListener('activated', () => {
      console.log('Service worker has been activated.')

      observeMessage()
    })
    wb.addEventListener('redundant', (e) => {
      console.log('Service worker redundant.')
      isRedundant = true
      setTimeout(() => {
        if (!registration) {
          console.error('unregister')
          if (navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              try {
                for (const registration of registrations) {
                  registration.unregister()
                    .catch(_ => {
                    })
                }
              } catch (ignore) {

              }
            })
          }
        }
      }, 30000)
    })
    wb.addEventListener('error', (e) => {
      console.error(e)
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          try {
            for (const registration of registrations) {
              registration.unregister()
                .catch(_ => {
                })
            }
          } catch (ignore) {

          }
        })
      }
    })
    wb.register().then((r) => {
      registration = r

      if (r.active) {
        observeMessage()
      }

      setTimeout(() => {
        if (r && !r.active) {
          console.error('unregister')
          r.unregister()
            .catch(_ => {
            })
        }
      }, isRedundant ? 10000 : 60000)
    }).catch(e => {
      console.error(e)
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          try {
            for (const registration of registrations) {
              registration.unregister()
                .catch(_ => {
                })
            }
          } catch (ignore) {

          }
        })
      }
    })
  } else {
    wb = null
  }
} else {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      try {
        for (const registration of registrations) {
          registration.unregister()
            .catch(_ => {
            })
        }
      } catch (ignore) {

      }
    })
  }
}
export default wb
