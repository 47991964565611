import Vue from 'vue'
/* @ts-ignore es-lint:disable @typescript-eslint/ban-ts-ignore */
import VueProgressBar from 'vue-progressbar'

const options = {
  color: '#0061f2',
  failedColor: '#E64069',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoFinish: true,
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)
