import { EMPTY, interval, Subject, Subscription } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import app from '@/main'
import axios, { AxiosRequestConfig } from 'axios'
import { State } from '@/store'
import { API_HOST } from '@/player/service/api'
import { ErrorCode, MessageError } from '@/player/model/error'
import { ResultCode } from '@/service/api'
import { Proto } from '@/player/model/proto'
import AlertModal from '@/player/components/modal/AlertModal.vue'
import { PlayerApiService } from '@/player/service/api.service'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
declare let moment: typeof moment

export class SecurityService {
  private static instance: SecurityService

  // tslint:disable-next-line:variable-name
  private _clientIpCache: [string, moment.Moment] | null = null
  // tslint:disable-next-line:variable-name
  private _ipTrackingSubscription: Subscription

  checkedIPList: [string, string, number, boolean, moment.Moment][] = []
  companyUID = ''
  logined = false

  securityErrorEventEmitter = new Subject<void>()

  public static getInstance(): SecurityService {
    return this.instance || (this.instance = new this())
  }

  private constructor() {
    this._ipTrackingSubscription = interval(5 * 60 * 1000)
      .pipe(
        mergeMap(() => {
          if (this.companyUID && this.logined) {
            return this.checkSecurityIP(0, this.companyUID)
          }
          return EMPTY
        }),
        catchError(e => {
          app.$log?.error(e)
          return EMPTY
        })
      )
      .subscribe(allow => {
        if (!allow) {
          this.securityErrorEventEmitter.next(undefined)
          PlayerApiService.logout()
            .subscribe(() => {
              app.$router?.push('/')
              const name = AlertModal.NAME + '-' + Math.random()
              app.$modal?.show(AlertModal, {
                name,
                message: '現在の環境ではご利用できません。管理者へお問い合わせください。'
              }, {
                name,
                adaptive: true,
                scrollable: true,
                height: 'auto'
              })
            })
        }
      })
  }

  private _clientIp(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).moment && this._clientIpCache && this._clientIpCache[1].diff(moment(), 'second') >= -60 * 10) {
      return Promise.resolve(this._clientIpCache[0])
    }
    return axios.get('https://api64.ipify.org', {
      responseType: 'text',
      timeout: 2000
    })
      .then(response => {
        return response.data as string
      })
      .catch(e => {
        app.$log?.debug(e)
        let url: string
        if (app && app.$store && (app.$store.state as State).meta.isCustom) {
          url = '/api/'
        } else {
          url = API_HOST
        }
        const request: AxiosRequestConfig = {
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'X-V-API': process.env.VUE_APP_PLAYER_API_VERSION,
            'X-E-API': process.env.VUE_APP_PLAYER_API_TOKEN,
            'X-TZ': (new Date().getTimezoneOffset() * 60).toString(10)
          },
          responseType: 'json',
          url: url + 'security/my-ip'
        }
        return axios.request(request)
          .catch(e => {
            if (e.isAxiosError && e.response && (e.response.status === 401 || e.response.status === 403)) {
              return Promise.resolve(e.response)
            }
            if (e.isAxiosError && !e.response) {
              return Promise.reject(MessageError.from(ErrorCode.NoInternet, e))
            }
            return Promise.reject(e)
          })
          .then(res => {
            let loginError = false
            if (res.status === 200) {
              if (res.data?.code === ResultCode.Login) {
                loginError = true
              }
            } else if (res.status === 401 || res.status === 403) {
              loginError = true
            }
            if (loginError) {
              return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
            } else if (res.status !== 200) {
              return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
            }
            return res.data
          })
          .then(result => {
            switch (result.code) {
              case Proto.ResultCode.Success:
                return result.data as string
              default:
                break
            }
            return Promise.reject(MessageError.from(ErrorCode.ServerInternal))
          })
      })
      .then(ip => {
        app.$log?.debug(`ip: ${ip}`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).moment && ip && ip !== '0.0.0.0' && ip !== '0:0:0:0:0:0') {
          this._clientIpCache = [ip, moment()]
        }
        return ip || '0.0.0.0'
      })
      .catch(e => {
        app.$log?.error(e)
        if (this._clientIpCache && this._clientIpCache[0]) {
          return Promise.resolve(this._clientIpCache[0])
        }
        return Promise.resolve('0.0.0.0')
      })
  }

  checkSecurityIPThrows(type?: number, companyUID?: string): Promise<void> {
    return this.checkSecurityIP(type, companyUID)
      .then(success => {
        if (!success) {
          throw MessageError.from(ErrorCode.ServerSecurityIP)
        }
      })
  }

  checkSecurityIP(type?: number, companyUID?: string): Promise<boolean> {
    const uid = companyUID || this.companyUID || '0'
    const t = type ?? 0
    return this._clientIp()
      .then(ip => {
        const data = this.checkedIPList.find(pair => pair[0] === uid && pair[1] === ip && pair[2] === t)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (data && (window as any).moment && data[4].diff(moment(), 'second') >= -60 * 5) {
          return data[3]
        }
        if (ip === '0.0.0.0') {
          return true
        }

        let url: string
        if (app && app.$store && (app.$store.state as State).meta.isCustom) {
          url = '/api/'
        } else {
          url = API_HOST
        }

        const request: AxiosRequestConfig = {
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'X-V-API': process.env.VUE_APP_PLAYER_API_VERSION,
            'X-E-API': process.env.VUE_APP_PLAYER_API_TOKEN,
            'X-TZ': (new Date().getTimezoneOffset() * 60).toString(10)
          },
          responseType: 'json',
          url: url + `security/ip-check?cuid=${uid}&t=${t}&ipaddr=${encodeURIComponent(ip)}`
        }
        return axios.request(request)
          .catch(e => {
            if (e.isAxiosError && e.response && (e.response.status === 401 || e.response.status === 403)) {
              return Promise.resolve(e.response)
            }
            if (e.isAxiosError && !e.response) {
              return Promise.reject(MessageError.from(ErrorCode.NoInternet, e))
            }
            return Promise.reject(e)
          })
          .then(res => {
            let loginError = false
            if (res.status === 200) {
              if (res.data?.code === ResultCode.Login) {
                loginError = true
              }
            } else if (res.status === 401 || res.status === 403) {
              loginError = true
            }
            if (loginError) {
              return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
            } else if (res.status !== 200) {
              return Promise.reject(MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText)))
            }
            return res.data
          })
          .then(result => {
            switch (result.code) {
              case Proto.ResultCode.Success:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (this.checkedIPList.findIndex(pair => pair[0] === uid && pair[1] === ip && pair[2] === t) < 0 && (window as any).moment) {
                  this.checkedIPList.push([uid, ip, t, true, moment()])
                }
                return true
              case Proto.ResultCode.Error:
                if (result.error?.code === Proto.ErrorCode.SECURITY_IP) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  if (this.checkedIPList.findIndex(pair => pair[0] === uid && pair[1] === ip && pair[2] === t) < 0 && (window as any).moment) {
                    this.checkedIPList.push([uid, ip, t, false, moment()])
                  }
                  return false
                }
                break
              default:
                break
            }
            return true
          })
          .catch(e => {
            app.$log?.error(e)
            return Promise.resolve(true)
          })
      })
  }
}
