/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue } from 'vue-property-decorator'
import { AsyncComponent, ComponentOptions } from 'vue'
import { EMPTY, Observable, of, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import store, { State } from '@/store'
import JoinModal from '@/player/components/modal/JoinModal.vue'
import { PlayerApiService } from '@/player/service/api.service'
import { TemplateData } from '@/player/model/json'
import { Proto } from '@/player/model/proto'
import ConfirmModal from '@/player/components/modal/ConfirmModal.vue'
import VueI18n from 'vue-i18n'
import BlockModal from '@/player/components/modal/BlockModal.vue'
import { ErrorCode, MessageError } from '@/player/model/error'
import { ContentType } from '@/player/model/constant'
import app from '@/main'
import { DefaultAppName } from '@/model/constant'
import { BrowserService } from '@/player/service/browser.service'

export const rootEventBus = new Vue()

export class PlayerUIService {
  public static joinModal(
    showModal: (
      name: string,
      component: typeof Vue | ComponentOptions<Vue> | AsyncComponent,
      componentProps?: object,
      modalProps?: object,
      modalEvents?: object) => void,
    loginMode: boolean,
    template?: TemplateData | null,
    planUID?: string,
    playlistUID?: string,
    contentUID?: string,
    params?: any,
    playlistUIDForJoinCheck?: string,
    showSignup?: boolean): Observable<void> {
    let blockCheck: Observable<unknown>
    if (store.state.playerAuth.uuid) {
      blockCheck = PlayerApiService.isBlock()
        .pipe(
          mergeMap(isBlocked => {
            if (isBlocked) {
              const name = BlockModal.NAME + '-' + Math.random()
              showModal(name, BlockModal, {
                planName: template?.name
              }, {
                clickToClose: true
              })
              return EMPTY
            }
            return of(undefined)
          }))
    } else {
      blockCheck = of(undefined)
    }
    return blockCheck
      .pipe(
        mergeMap(_ => {
          if (store.state.playerAuth.uuid) {
            return PlayerApiService.joinAvailable(playlistUID)
              .pipe(
                mergeMap(() => PlayerApiService.preCheckWithUser(planUID, playlistUID, contentUID, undefined, params)),
                mergeMap(url => {
                  if (url && store.state.meta.notAllowRegister) {
                    if (playlistUID || contentUID || planUID) {
                      return throwError(MessageError.from(ErrorCode.NotAllowRegisterForContent))
                    }
                    return throwError(MessageError.from(ErrorCode.NotAllowRegister))
                  }
                  window.location.href = url
                  return of(false)
                }), catchError(e => {
                  if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginFail) {
                    return of(true)
                  } else if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoThirdOAuthUser) {
                    return of(true)
                  }
                  return throwError(e)
                })
              )
          }
          return of(true)
        }),
        mergeMap(needJoin => {
          if (needJoin) {
            return new Observable<void>(subscriber => {
              const name = JoinModal.NAME + '-' + Math.random()
              showModal(name, JoinModal, {
                planUID,
                playlistUID,
                contentUID,
                loginMode,
                playlistUIDForJoinCheck,
                planName: template?.name,
                joinParams: params,
                showSignup: showSignup ?? false
              }, {
                width: 460
              }, {
                closed: () => {
                  subscriber.next()
                  subscriber.complete()
                }
              })
            })
          }
          return of(undefined)
        })
      )
  }

  public static openContent(playlistUID: string, content: Proto.IContent, query?: any, replace?: boolean): void {
    this.openContentWithType(playlistUID, content.uid as string, content.type as ContentType, query, replace)
  }

  public static openContentWithType(
    playlistUID: string,
    contentUID: string,
    type: ContentType,
    query?: any,
    replace?: boolean): void {
    const path = this.contentPathWithType(playlistUID, contentUID, type)
    if (replace) {
      app.$router.replace({
        path,
        query
      })
    } else {
      app.$router.push({
        path,
        query
      })
    }
  }

  public static contentPath(playlistUID: string, content: Proto.IContent): string {
    return this.contentPathWithType(playlistUID, content.uid as string, content.type as ContentType)
  }

  public static contentPathWithType(playlistUID: string, contentUID: string, type: ContentType): string {
    if (ContentType.isEvent(type)) {
      return `/player/playlist/${playlistUID}/event/${contentUID}`
    }
    return `/player/playlist/${playlistUID}/video/${contentUID}`
  }

  public static appUrl(companyUID: string, playlistUID?: string, contentUID?: string, contentType?: number): string {
    let url = `${process.env.VUE_APP_API_HOST}app-auto?cp=${companyUID as string}`
    if (playlistUID) {
      url += `&pl=${playlistUID}`
      if (contentUID) {
        url += `&c=${contentUID}`
        if (contentType) {
          url += `&t=${contentType}`
        }
      }
    }
    return url
  }

  public static chatMessageUrl(apns: number, companyUID: string, userUID?: string, nickname?: string): string {
    let url = `${process.env.VUE_APP_API_HOST}app-auto?cp=${companyUID}&mc=${companyUID}&nt=${apns}&forcefb=1&forceMobile=1`
    if (userUID) {
      url += `&mui=${userUID}`
    }
    if (nickname) {
      url += `&mun=${encodeURIComponent(nickname)}`
    }
    return url
  }

  public static appStoreUrl(domain: string, osType: number) {
    return `${process.env.VUE_APP_API_HOST}subscription/web/d/${encodeURIComponent(domain)}/appstore/redirect?os=${osType}`
  }

  public static openApp(
    showModal: (
      name: string,
      component: typeof Vue | ComponentOptions<Vue> | AsyncComponent,
      componentProps?: object,
      modalProps?: object,
      modalEvents?: object) => void,
    tc: (key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values) => string,
    companyUID: string,
    playlistUID?: string,
    contentUID?: string,
    contentType?: number,
    target?: string,
    message?: string) {
    const appName = (app.$store?.state as State)?.playerMeta?.appName
    const name = ConfirmModal.NAME + '-' + Math.random()
    showModal(name, ConfirmModal, {
      message: message ?? tc('player.text.modal.mobileapp.confirm', undefined, { appName: appName || DefaultAppName }),
      okButton: tc('player.text.modal.mobileapp.ok'),
      cancelButton: tc('player.text.modal.mobileapp.no'),
      result: (result) => {
        if (result) {
          const url = PlayerUIService.appUrl(companyUID, playlistUID, contentUID, contentType)
          if (target) {
            window.open(url, target)
          } else {
            window.location.href = url
          }
        }
      }
    })
  }

  public static shareContentToSNS(type: number, playlistUID: string, content: Proto.IContent): void {
    const shareUrl = `${process.env.VUE_APP_SHARE_HOST}share/playlist/${playlistUID}/content/${content.uid || ''}`
    let text = `${content.title}`
    let attachUrl = true
    if ((app.$store?.state as State)?.playerMeta?.subdomain === 'tmp') {
      text += `\n${shareUrl} via @livai__official`
      attachUrl = false
    }
    this.shareSNS(type, text, shareUrl, attachUrl)
  }

  public static shareSNS(type: number, text: string, shareUrl: string, attachUrl?: boolean): void {
    let openUrl = ''
    switch (type) {
      case 1:
        openUrl = `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(text)}&u=${encodeURIComponent(shareUrl)}`
        break
      case 2:
        if (text.length >= 145) {
          text = text.substring(0, 145) + '...'
        }
        if (attachUrl === undefined || attachUrl) {
          openUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareUrl)}`
        } else {
          openUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`
        }
        break
      case 3:
        openUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(text)}`
        break
    }
    if (openUrl) {
      if (BrowserService.isMobileDevice()) {
        window.location.href = openUrl
      } else {
        window.open(openUrl, 'LITEVIEWSharePopup', 'left=0,top=0,location=no,menubar=no,status=no,toolbar=no,resizable=yes')
      }
    }
  }
}
