




































import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import { finalize } from 'rxjs/operators'
import { PlayerApiService } from '@/player/service/api.service'
import AlertModal from '@/player/components/modal/AlertModal.vue'
import { State } from '@/store'
import ContactModal from '@/player/components/modal/ContactModal.vue'

@Component
export default class ForgotModal extends AbstractModalVue {
  public static NAME = 'forgot-modal'

  @Prop() showContact!: boolean

  submitting = false
  email = ''
  errors = {
    email: '',
    validated: false
  }

  focused = {
    email: false
  }

  btobName = ''

  created() {
    Promise.resolve((this.$store.state as State).meta.btoblogo)
      .then(template => {
        if (template.name) {
          this.btobName = template.name
        }
      })
  }

  focus(key: string) {
    this.focused[key] = true
  }

  validateForm(showError: boolean) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const email = this.email && this.email.length && re.test(this.email) ? '' : this.$tc('player.error.msg.email_required')
    this.errors.validated = !email
    if (showError) {
      if (this.focused.email) {
        this.errors.email = email
      }
    } else {
      if (!email) {
        this.errors.email = email
      }
    }
  }

  handleSubmit(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.focus('email')
    this.validateForm(true)
    if (!this.errors.validated) {
      return
    }

    this.$Progress.start()
    this.submitting = true
    PlayerApiService.forgot(this.email)
      .pipe(finalize(() => {
        this.$Progress.finish()
        this.submitting = false
      }))
      .subscribe(() => {
        this.hide()
        const name = AlertModal.NAME + '-' + Math.random()
        this.showModal(name, AlertModal, {
          title: this.$tc('player.text.modal.forgot.title'),
          message: this.$tc('player.text.modal.forgot.success')
        })
      }, e => {
        this.$log.error(e)
        this.showAlert(e)
      })
  }

  contact(event?: Event) {
    event?.preventDefault()
    event?.stopPropagation()
    const name = ContactModal.NAME + '-' + Math.random()
    this.showModal(name, ContactModal, {
      planName: (this.$store.state as State).playerMeta.template?.name,
      companyUID: (this.$store.state as State).playerMeta.template?.companyUID
    })
  }

  hide() {
    super.hide()
  }
}
