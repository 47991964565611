















import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import { MessageError } from '@/model/error'
import { MessageError as PlayerMessageError } from '@/player/model/error'

@Component
export default class ConfirmModal extends AbstractModalVue {
  public static NAME = 'confirm-modal'

  @Prop() private title!: string | undefined
  @Prop() private message!: string | undefined
  @Prop() private error!: Error | undefined
  @Prop() private result!: (result: boolean) => void | undefined
  @Prop() private okButton!: string | undefined
  @Prop() private cancelButton!: string | undefined
  @Prop() private cancelButtonClass!: string | undefined

  private errMsg = ''
  private button = {
    ok: this.$t('text.ok'),
    cancel: this.$t('text.cancel')
  }

  beforeMount() {
    if (this.error) {
      if (this.error instanceof MessageError) {
        this.errMsg = (this.error as MessageError).getMessage()
      } else if (this.error instanceof PlayerMessageError) {
        this.errMsg = (this.error as PlayerMessageError).getMessage()
      } else {
        this.errMsg = this.error.message
      }
    } else {
      this.errMsg = this.message ?? ''
    }
    if (this.okButton) {
      this.button.ok = this.okButton
    }
    if (this.cancelButton) {
      this.button.cancel = this.cancelButton
    }
  }

  close(result: boolean) {
    this.hide()
    if (this.result) {
      this.result(result)
    }
  }

  hide() {
    super.hide()
  }
}
