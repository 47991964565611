import { ScriptService } from '@/player/service/script.service'
import { Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

let momentInit = false
const momentVersion = '2.29.1'

export class DateService {
  static init(): Observable<boolean> {
    if (!momentInit) {
      momentInit = true
      return ScriptService.loadScript(`https://fastly.jsdelivr.net/npm/moment@${momentVersion}/moment.min.js`)
        .pipe(mergeMap(() => DateService.setLocale()))
    }
    return of(true)
  }

  static setLocale(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const navigator = window.navigator as any
    // const userLanguage = (navigator.languages && navigator.languages[0]) ||
    //   navigator.language ||
    //   navigator.userLanguage ||
    //   navigator.browserLanguage
    // let locale = ''
    // if (userLanguage && userLanguage.length > 0) {
    //   const index = userLanguage.indexOf('-')
    //   if (index >= 0) {
    //     locale = userLanguage.substr(0, index)
    //   } else {
    //     locale = userLanguage
    //   }
    // }
    // const momentLocale = locale === 'ja' ? 'ja' : 'en-ie'
    const momentLocale = 'ja'
    return ScriptService.loadScript(`https://fastly.jsdelivr.net/npm/moment@${momentVersion}/locale/${momentLocale}.min.js`)
      .pipe(map(() => {
        moment.locale(momentLocale)
        return true
      }))
  }
}
