import { from, Observable, of } from 'rxjs'
import { ErrorCode, MessageError } from '@/model/error'
import { FileService } from '@/service/file.service'
import { corsURL, isAPIHost } from '@/model/constant'
import { ScriptService } from '@/player/service/script.service'
import { map, mergeMap, toArray } from 'rxjs/operators'
import { fromPromise } from 'rxjs/internal-compatibility'

export class ImageService {
  public static cropImage(
    imageElement: HTMLImageElement,
    x: number,
    y: number,
    width: number,
    height: number,
    type?: 'image/jpeg' | 'image/png'): string | null {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (context) {
      context.drawImage(imageElement,
        x, y, width, height,
        0, 0, canvas.width, canvas.height)
      return canvas.toDataURL(type ?? 'image/jpeg')
    }
    return null
  }

  public static resizeImage(
    imageElement: HTMLImageElement,
    baseLength: number,
    type?: 'image/jpeg' | 'image/png',
    clip?: boolean): string | null {
    let width = imageElement.width
    let height = imageElement.height
    if (width < height) {
      height = height * baseLength / width
      width = baseLength
    } else {
      width = width * baseLength / height
      height = baseLength
    }
    const canvas = document.createElement('canvas')
    if (clip) {
      if (width < height) {
        canvas.width = Math.floor(height * 16 / 9)
        canvas.height = height
      } else {
        if (width < height * 16 / 9) {
          width = height * 16 / 9
          height = width / imageElement.width * imageElement.height
          canvas.width = width
          canvas.height = Math.floor(width / 16 * 9)
        } else {
          canvas.width = Math.floor(height * 16 / 9)
          canvas.height = height
        }
      }
    } else {
      canvas.width = width
      canvas.height = height
    }
    const dx = (canvas.width - width) / 2
    const dy = (canvas.height - height) / 2
    const context = canvas.getContext('2d')
    if (context) {
      context.drawImage(imageElement, dx, dy, width, height)
      const dataType = type ?? 'image/jpeg'
      return canvas.toDataURL(dataType)
    }
    return null
  }

  public static toDataURL(imageElement: HTMLImageElement, type?: 'image/jpeg' | 'image/png'): string | null {
    const canvas = document.createElement('canvas')
    canvas.width = imageElement.width
    canvas.height = imageElement.height
    const context = canvas.getContext('2d')
    if (context) {
      context.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height)
      return canvas.toDataURL(type ?? 'image/jpeg')
    }
    return null
  }

  public static rotateImage(
    imageElement: HTMLImageElement,
    degree: number,
    type?: 'image/jpeg' | 'image/png'): string | null {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    let cw = imageElement.width
    let ch = imageElement.height
    let cx = 0
    let cy = 0

    //   Calculate new canvas size and x/y coorditates for image
    switch (degree) {
      case 90:
        cw = imageElement.height
        ch = imageElement.width
        cy = imageElement.height * (-1)
        break
      case 180:
        cx = imageElement.width * (-1)
        cy = imageElement.height * (-1)
        break
      case -90:
      case 270:
        cw = imageElement.height
        ch = imageElement.width
        cx = imageElement.width * (-1)
        break
    }

    //  Rotate image
    canvas.width = cw
    canvas.height = ch
    if (context) {
      context.rotate(degree * Math.PI / 180)
      context.drawImage(imageElement, cx, cy)

      return canvas.toDataURL(type ?? 'image/jpeg')
    }
    return null
  }

  public static loadImage(url: string): Observable<HTMLImageElement> {
    return new Observable(subscriber => {
      const imgElement = document.createElement('img')
      imgElement.crossOrigin = isAPIHost(url) ? 'use-credentials' : 'anonymous'
      imgElement.onload = () => {
        subscriber.next(imgElement)
        subscriber.complete()
      }
      imgElement.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        subscriber.error(MessageError.from(ErrorCode.ImageLoadFailed, error))
      }
      imgElement.src = url
    })
  }

  public static loadCORSImage(url: string): Observable<HTMLImageElement> {
    return this.loadImage(corsURL(url))
  }

  public static imageFileToDataURL(file: Blob): Observable<string> {
    return new Observable(subscriber => {
      const fileSize = file.size
      const chunkSize = 65536 // 64 * 1024
      const chunksData: Uint8Array[] = []
      let currentChunk = 0

      const readEventHandler = (evt) => {
        currentChunk++
        chunksData.push(new Uint8Array(evt.target.result as ArrayBuffer))
        if (currentChunk >= Math.ceil(fileSize / chunkSize)) {
          const combinedData = new Uint8Array(fileSize)
          let offset = 0
          chunksData.forEach(chunk => {
            combinedData.set(chunk, offset)
            offset += chunk.byteLength
          })
          const reader = new FileReader()
          reader.onloadend = () => {
            subscriber.next(reader.result as string)
            subscriber.complete()
          }
          reader.onerror = () => {
            subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
          }
          reader.readAsDataURL(new Blob([combinedData], { type: file.type }))
        } else {
          chunkReaderBlock()
        }
      }

      const chunkReaderBlock = () => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          readEventHandler(e)
        }
        reader.onerror = () => {
          subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
        }
        const start = currentChunk * chunkSize
        const end = Math.min(start + chunkSize, fileSize)
        const blob = file.slice(start, end, file.type)
        reader.readAsArrayBuffer(blob)
      }
      chunkReaderBlock()
    })
  }

  public static decodeImageBuffer(buffer: Iterable<number>): string | null {
    let mime
    const a = new Uint8Array(buffer)
    const nb = a.length
    if (nb < 4) {
      return null
    }
    const b0 = a[0]
    const b1 = a[1]
    const b2 = a[2]
    const b3 = a[3]
    if (b0 === 0x89 && b1 === 0x50 && b2 === 0x4E && b3 === 0x47) {
      mime = 'image/png'
    } else if (b0 === 0xff && b1 === 0xd8) {
      mime = 'image/jpeg'
    } else if (b0 === 0x47 && b1 === 0x49 && b2 === 0x46) {
      mime = 'image/gif'
    } else {
      return null
    }
    let binary = ''
    for (let i = 0; i < nb; i++) {
      binary += String.fromCharCode(a[i])
    }
    const base64 = window.btoa(binary)
    return 'data:' + mime + ';base64,' + base64
  }

  private static _base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }

  public static decodeDataURIToBuffer(uri: string): ArrayBuffer {
    // strip off the data: url prefix to get just the base64-encoded bytes
    const data = uri.replace(/^data:image\/\w+;base64,/, '')
    return ImageService._base64ToArrayBuffer(data)
  }

  public static decodeDataURIToFile(uri: string): File {
    const regex = uri.match(/^data:(image\/\w+);base64,/)
    const mime = regex !== null ? regex[1] : 'image/jpeg'
    let filename = 'image.jpg'
    if (mime === 'image/png') {
      filename = 'image.png'
    } else if (mime === 'image/gif') {
      filename = 'image.gif'
    } else if (mime === 'image/tiff') {
      filename = 'image.tiff'
    } else if (mime === 'image/heic') {
      filename = 'image.heic'
    }
    return FileService.bufferToFile(ImageService.decodeDataURIToBuffer(uri), filename, mime)
  }

  public static convertHEICToJPG(
    files: File[],
    type: 'image/jpeg' | 'image/png' = 'image/jpeg',
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    quality: number = 1.0): Observable<File[]> {
    return ScriptService.loadHEICJPG()
      .pipe(
        mergeMap(() => from(files)),
        mergeMap(file => {
          const ext = file.name.split('.').pop() || ''
          if (['heic', 'heif'].indexOf(ext.toLowerCase()) < 0) {
            return of(file)
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return fromPromise((window as any).heic2any({
            blob: file,
            toType: type,
            quality
          }) as Promise<Blob>)
            .pipe(
              mergeMap(blob => FileService.blobToArrayBuffer(blob)),
              map(ab => {
                const ext = file.name.split('.').pop()
                const toExt = type === 'image/png' ? '.png' : '.jpg'
                return FileService.newFile([ab], file.name.replace(`.${ext}`, toExt), { type })
              })
            )
        }),
        toArray()
      )
  }
}
