






















import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import ContactEmailModal from '@/player/components/modal/ContactEmailModal.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'

@Component({
  components: {
    CloseIcon
  }
})
export default class BlockModal extends AbstractModalVue {
  public static NAME = 'block-modal'

  @Prop() planName!: string

  contact(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.hide()
    const name = ContactEmailModal.NAME + '-' + Math.random()
    this.showModal(name, ContactEmailModal, {
      planName: this.planName
    }, {
      clickToClose: false
    })
  }

  hide() {
    super.hide()
  }
}
