




















import { Component, Prop } from 'vue-property-decorator'
import { MessageError } from '@/model/error'
import { MessageError as PlayerMessageError } from '@/player/model/error'
import { AbstractModalVue } from '@/views/AbstractModalVue'

@Component
export default class AlertModal extends AbstractModalVue {
  public static NAME = 'alert-modal'

  @Prop() private title!: string | undefined
  @Prop() private message!: string | undefined
  @Prop() private error!: Error | undefined

  private errMsg = ''
  private errMsgHtml = false

  beforeMount() {
    if (this.error) {
      if (this.error instanceof MessageError) {
        this.errMsg = (this.error as MessageError).getMessage()
      } else if (this.error instanceof PlayerMessageError) {
        this.errMsg = (this.error as PlayerMessageError).getMessage()
      } else {
        this.errMsg = this.error.message
      }
    } else {
      this.errMsg = this.message ?? ''
    }
    if (this.errMsg && ((this.errMsg.startsWith('<') && this.errMsg.endsWith('>')) || this.errMsg.indexOf('<br/>') >= 0)) {
      this.errMsgHtml = true
    }
  }

  hide() {
    super.hide()
  }
}
