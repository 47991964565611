import { Observable } from 'rxjs'
import { BrowserService } from '@/service/browser.service'
import { ErrorCode, MessageError } from '@/model/error'

export class FileService {
  public static newFile(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag): File {
    if (BrowserService.isIE()) {
      const b = new Blob(fileBits)
      // if (options && options.type) {
      //   (b as any).type = options.type;
      // }
      // if (options && options.lastModified) {
      //   (b as any).lastModified = options.lastModified;
      // }
      return FileService.blobToFile(b, fileName)
    } else {
      return new File(fileBits, fileName, options)
    }
  }

  public static loadFile(type?: string): Observable<File> {
    return new Observable(subscriber => {
      const file = document.createElement('input')
      file.type = 'file'
      if (type) {
        file.accept = type
      }
      file.onclick = () => {
        file.value = ''
      }
      file.onchange = () => {
        const files = file.files
        if (!files || files.length === 0) {
          subscriber.complete()
          return
        }
        subscriber.next(files[0])
        subscriber.complete()
      }
      file.click()
    })
  }

  public static blobToArrayBuffer(blob: Blob): Observable<ArrayBuffer> {
    return new Observable<ArrayBuffer>(subscriber => {
      const blobSize = blob.size
      const chunkSize = 65536 // 64 * 1024
      const chunksData: Uint8Array[] = []
      let currentChunk = 0

      const readEventHandler = (evt) => {
        currentChunk++
        chunksData.push(new Uint8Array(evt.target.result as ArrayBuffer))
        if (currentChunk >= Math.ceil(blobSize / chunkSize)) {
          const combinedData = new Uint8Array(blobSize)
          let offset = 0
          chunksData.forEach(chunk => {
            combinedData.set(chunk, offset)
            offset += chunk.byteLength
          })
          const reader = new FileReader()
          reader.onloadend = () => {
            subscriber.next(reader.result as ArrayBuffer)
            subscriber.complete()
          }
          reader.onerror = () => {
            subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
          }
          reader.readAsArrayBuffer(new Blob([combinedData], { type: blob.type }))
        } else {
          chunkReaderBlock()
        }
      }

      const chunkReaderBlock = () => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          readEventHandler(e)
        }
        reader.onerror = () => {
          subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
        }
        const start = currentChunk * chunkSize
        const end = Math.min(start + chunkSize, blobSize)
        const slicedBlob = blob.slice(start, end, blob.type)
        reader.readAsArrayBuffer(slicedBlob)
      }
      chunkReaderBlock()
    })
  }

  public static blobToString(blob: Blob): Observable<string> {
    return new Observable<string>(subscriber => {
      const blobSize = blob.size
      const chunkSize = 65536 // 64 * 1024
      const chunksData: Uint8Array[] = []
      let currentChunk = 0

      const readEventHandler = (evt) => {
        currentChunk++
        chunksData.push(new Uint8Array(evt.target.result as ArrayBuffer))
        if (currentChunk >= Math.ceil(blobSize / chunkSize)) {
          const combinedData = new Uint8Array(blobSize)
          let offset = 0
          chunksData.forEach(chunk => {
            combinedData.set(chunk, offset)
            offset += chunk.byteLength
          })
          const reader = new FileReader()
          reader.onloadend = () => {
            subscriber.next(reader.result as string)
            subscriber.complete()
          }
          reader.onerror = () => {
            subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
          }
          reader.readAsText(new Blob([combinedData], { type: blob.type }), 'utf-8')
        } else {
          chunkReaderBlock()
        }
      }

      const chunkReaderBlock = () => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          readEventHandler(e)
        }
        reader.onerror = () => {
          subscriber.error(MessageError.from(ErrorCode.FileLoadFailed, reader.error))
        }
        const start = currentChunk * chunkSize
        const end = Math.min(start + chunkSize, blobSize)
        const slicedBlob = blob.slice(start, end, blob.type)
        reader.readAsArrayBuffer(slicedBlob)
      }
      chunkReaderBlock()
    })
  }

  public static bufferToFile(buffer: ArrayBuffer, filename: string, mime: string): File {
    let blob: Blob
    if (BrowserService.isIE()) {
      blob = new Blob([buffer])
    } else {
      blob = new Blob([buffer], { type: mime })
    }
    return FileService.blobToFile(blob, filename)
  }

  public static blobToFile(theBlob: Blob, fileName: string): File {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const b: any = theBlob
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date()
    b.name = fileName

    // Cast to a File() type
    return theBlob as File
  }

  public static bufferToArrayBuffer(buf: Uint8Array): ArrayBuffer {
    const ab = new ArrayBuffer(buf.length)
    const view = new Uint8Array(ab)
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i]
    }
    return ab
  }

  public static filename(path: string): string {
    /* eslint-disable no-useless-escape */
    return path.replace(/^.*?([^\\\/]*)$/, '$1')
  }

  public static dataURItoBlob(dataURI: string): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  }
}
