






























import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import { State } from '@/store'
import { APIService } from '@/service/api.service'
import { takeUntil } from 'rxjs/operators'

@Component({
  components: {
    CloseIcon
  }
})
export default class PrivateCommunityModal extends AbstractModalVue {
  public static NAME = 'private-community-modal'

  @Prop() planName!: string
  @Prop() domainList!: string[]

  title = ''

  created() {
    const domain = (this.$store.state as State).meta.subdomain
    const service = new APIService(domain)
    service.maliFilterInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(info => {
        this.title = info.title || this.$tc('player.text.modal.private-community.title', undefined, { value: this.planName })
      }, e => {
        this.$log.error(e)
        this.title = this.$tc('player.text.modal.private-community.title', undefined, { value: this.planName })
      })
  }

  hide() {
    super.hide()
  }
}
