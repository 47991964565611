import { LogoData, TemplateData } from '@/player/model/json'
import { PlayerApiService } from '@/player/service/api.service'
import app from '@/main'
import { State } from '@/store'

const namespace = 'playerMeta/'

const LOCALE = namespace + 'LOCALE'
const SUBDOMAIN = namespace + 'SUBDOMAIN'
const TEMPLATE = namespace + 'TEMPLATE'
const LOGO = namespace + 'LOGO'
const APPBANNER = namespace + 'APPBANNER'
const SHAKAPOLYFILL = namespace + 'SHAKAPOLYFILL'
const CATCHHEADERLOGIN = namespace + 'CATCHHEADERLOGIN'
const NOTIFICATIONCOUNT = namespace + 'NOTIFICATIONCOUNT'
const NOTIFICATIONUPDATECOUNT = namespace + 'NOTIFICATIONUPDATECOUNT'
const CHANNELIO = namespace + 'CHANNELIO'

export class PlayerLocaleActionPayload {
  public static type = LOCALE
  public readonly type = LOCALE

  constructor(readonly locale: string) {
  }
}

export class PlayerSubdomainActionPayload {
  public static type = SUBDOMAIN
  public readonly type = SUBDOMAIN

  constructor(readonly subdomain: string) {
  }
}

export class PlayerTemplateActionPayload {
  public static type = TEMPLATE
  public readonly type = TEMPLATE

  constructor(readonly template: TemplateData) {
  }
}

export class PlayerLogoActionPayload {
  public static type = LOGO
  public readonly type = LOGO

  constructor(readonly logo: LogoData) {
  }
}

export class PlayerAppBannerActionPayload {
  public static type = APPBANNER
  public readonly type = APPBANNER

  constructor(readonly appBanner: (() => void) | null) {
  }
}

export class PlayerShakaPolyfillActionPayload {
  public static type = SHAKAPOLYFILL
  public readonly type = SHAKAPOLYFILL
}

export class PlayerCatchHeaderLoginActionPayload {
  public static type = CATCHHEADERLOGIN
  public readonly type = CATCHHEADERLOGIN

  constructor(readonly fn: (() => void) | null) {
  }
}

export class PlayerNotificationCountActionPayload {
  public static type = NOTIFICATIONCOUNT
  public readonly type = NOTIFICATIONCOUNT

  constructor(readonly count: number, readonly date: Date) {
  }
}

export class PlayerUpdateNotificationCountActionPayload {
  public static type = NOTIFICATIONUPDATECOUNT
  public readonly type = NOTIFICATIONUPDATECOUNT

  constructor(refresh?: boolean) {
    const store = app.$store
    if (!store) {
      return
    }
    if (!(store.state as State).playerAuth.uuid) {
      app.$store?.commit(new PlayerNotificationCountActionPayload(0, new Date()))
      return
    }
    const before = (store.state as State).playerMeta.notificationCountDate
    if (refresh || !before || new Date().getTime() - before.getTime() >= 5 * 60 * 1000) {
      PlayerApiService.userNotificationCount()
        .subscribe(count => {
          app.$store?.commit(new PlayerNotificationCountActionPayload(count, new Date()))
        }, e => {
          app.$log?.error(e)
        })
    }
  }
}

export class PlayerChannelIOActionPayload {
  public static type = CHANNELIO
  public readonly type = CHANNELIO

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly key?: any) {
  }
}
