import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ja from './message/ja.json'
import axios from 'axios'
import router from '../router'
import { Locales } from './locale'
import store from 'store'

Vue.use(VueI18n)

export const messages = {
  [Locales.JA]: ja
}

export const i18n = new VueI18n({
  locale: store.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages // set locale messages
})

const loadedLanguages: string[] = [] // our default language that is preloaded

function setI18nLanguage(lang: string): string {
  i18n.locale = lang
  store.set('locale', lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  const html = document.querySelector('html')
  if (html) {
    html.setAttribute('lang', lang)
  }
  return lang
}

export const i18nUtil = {
  availableLocales: (process.env.VUE_APP_AVAILABLE_I18n || 'ja').split(','),
  changeLang: function (lang: string) {
    if (router.currentRoute.query.lang !== lang) {
      router.push({ query: { lang: lang } })
    }
  },
  loadLanguageAsync: function (lang: string) {
    // If the same language
    if (i18n.locale === lang && loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    if (!lang) {
      lang = i18n.locale
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    if (!i18nUtil.availableLocales.includes(lang)) {
      lang = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja'
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ ('@/i18n/message/' + lang + '.json')).then(
      messages => {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
    )
  }
}

export default i18n
