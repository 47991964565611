












































import { Component } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import { PlayerApiService } from '@/player/service/api.service'
import { finalize } from 'rxjs/operators'
import AlertModal from '@/player/components/modal/AlertModal.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'

@Component({
  components: {
    CloseIcon
  }
})
export default class SiteViolationModal extends AbstractModalVue {
  public static NAME = 'site-violation-modal'

  // @Prop() contentUID!: string

  types: [number, string][] = []

  body = ''
  selectedType = 0
  errors = {
    body: '',
    validated: false
  }

  focused = {
    body: false
  }

  created() {
    for (let i = 1; i <= 8; i++) {
      this.types.push([i, this.$tc(`player.text.modal.site_violation.type_${i}`)])
    }
  }

  focus(key: string) {
    this.focused[key] = true
  }

  submitting = false

  validateForm(showError: boolean) {
    const body = this.body && this.body.length ? '' : this.$tc('player.error.msg.body_required')
    this.errors.validated = !body
    if (showError) {
      if (this.focused.body) {
        this.errors.body = body
      }
    } else {
      if (!body) {
        this.errors.body = body
      }
    }
  }

  send(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.focus('body')
    this.validateForm(true)
    if (!this.errors.validated) {
      return
    }

    this.$Progress.start()
    this.submitting = true
    PlayerApiService.companyViolation(this.selectedType, this.body)
      .pipe(finalize(() => {
        this.$Progress.finish()
        this.submitting = false
      }))
      .subscribe((email) => {
        this.hide()
        const name = AlertModal.NAME + '-' + Math.random()
        this.showModal(name, AlertModal, {
          title: this.$tc('player.text.modal.site_violation.title'),
          message: this.$tc('player.text.modal.site_violation.success')
        })
      }, e => {
        this.$log.error(e)
        this.showAlert(e)
      })
  }

  hide() {
    super.hide()
  }
}
