import Vue from 'vue'
import Vuex from 'vuex'
import auth, { State as AuthState } from './auth'
import error, { State as ErrorState } from './error'
import meta, { State as MetaState } from './meta'
import playerAuth, { State as PlayerAuthState } from './player/auth'
import playerMeta, { State as PlayerMetaState } from './player/meta'

Vue.use(Vuex)

export type State = {
  meta: MetaState;
  auth: AuthState;
  error: ErrorState;
  playerMeta: PlayerMetaState;
  playerAuth: PlayerAuthState;
}

const state = new Vuex.Store<State>({
  modules: {
    meta,
    auth,
    error,
    playerMeta,
    playerAuth
  },
  getters: {
    isCompanyOwner: (): boolean => {
      if (state.state.playerMeta.template) {
        return state.state.playerMeta.template.companyUID === state.state.playerAuth.loginUserCP
      }
      return false
    },
    isCompanySubOwner: (): boolean => {
      return state.getters.isCompanyOwner && state.state.playerAuth.user?.userType === 12
    },
    serviceName: (): string => {
      if (state.state.playerMeta.template) {
        const tmp = document.createElement('DIV')
        tmp.innerHTML = state.state.playerMeta.template.name
        return (tmp.textContent || tmp.innerText || '').trim()
      }
      return ''
    }
  }
})

export default state
