const namespace = 'auth/'

const LOGIN = namespace + 'LOGIN'
const LOGOUT = namespace + 'LOGOUT'
const AUTHENTICATION_FAILED = namespace + 'AUTHENTICATION_FAILED'

export class LoginActionPayload {
  public static type = LOGIN
  public readonly type = LOGIN

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly user: any) {
  }
}

export class LogoutActionPayload {
  public static type = LOGOUT
  public readonly type = LOGOUT
}

export class AuthFailedPayload {
  public static readonly type = AUTHENTICATION_FAILED
  public readonly type = AUTHENTICATION_FAILED
}
