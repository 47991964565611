const namespace = 'error/'

const ERROR = namespace + 'ERROR'

export class ErrorActionPayload {
  public static type = ERROR
  public readonly type = ERROR

  /* eslint-disable handle-callback-err */
  constructor(readonly error: Error) {
  }
}
