import { Proto } from '@/player/model/proto'
import PlayerHttp from '@/player/service/api'
import { PlayerApiService } from '@/player/service/api.service'
import app from '@/main'

export class PlayerStatService {
  private static instance: PlayerStatService

  private _typeLoading: Promise<void>
  private _postedIdList: string[] = []
  private _statWaitingList: Proto.IContentPlayStatRequest[] = []
  private _isRealtime = false
  private _userLogEnabled?: boolean
  private _deviceKeyPromise?: Promise<string>

  private constructor() {
    this._typeLoading = PlayerApiService.appFeaturesTypeList()
      .toPromise()
      .then(typeList => {
        this._userLogEnabled = typeList.indexOf(22) >= 0
        this._isRealtime = typeList.indexOf(12) >= 0
        if (this._isRealtime && this._statWaitingList.length) {
          this.sendRequest()
        }
      })
      .catch(e => {
        app.$log?.error(e)
      })
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.sendRequest()
      } else {
        if (this._userLogEnabled && app.$store?.state?.playerAuth?.uuid) {
          PlayerApiService.addLogUserAccess()
            .catch(e => app.$log?.error(e))
        }
      }
    }, false)
  }

  public static getInstance(): PlayerStatService {
    return this.instance || (this.instance = new this())
  }

  public get isUserLogEnabled(): Promise<boolean> {
    if (this._userLogEnabled === undefined) {
      return this._typeLoading
        .then(() => this._userLogEnabled ?? false)
    }
    return Promise.resolve(this._userLogEnabled)
  }

  public get isRealtime(): boolean {
    return this._isRealtime
  }

  public userChanged() {
    this._deviceKeyPromise = PlayerApiService.statDeviceKey()
      .catch(e => {
        app.$log?.error(e)
        return Promise.resolve('')
      })
  }

  public addPlayStat(data: Proto.IContentPlayStatRequest): Promise<Proto.IContentPlayStatResponse> {
    if (!this._deviceKeyPromise) {
      this.userChanged()
    }
    return (this._deviceKeyPromise ?? Promise.resolve('')).then(key => {
      data.deviceKey = key
      if (!data.playId) {
        data.playId = new Date().getTime().toString(10)
        data.bufferId = new Date().getTime().toString(10)
        data.ts = new Date().getTime()
      }
      const index = this._statWaitingList.findIndex(d => d.playId === data.playId)
      if (index >= 0) {
        data.ts = this._statWaitingList[index].ts
        this._statWaitingList[index] = data
      } else if (this._postedIdList.indexOf(data.playId) < 0) {
        this._statWaitingList.push(data)
      }
      return Proto.ContentPlayStatResponse.create({
        playId: data.playId,
        bufferId: data.bufferId
      })
    })
  }

  sendRequest() {
    const body = this._statWaitingList
    this._postedIdList.push(...body.map(d => d.playId || '').filter(id => !!id))
    this._statWaitingList = []
    if (body.length) {
      const headers = {
        type: 'application/json'
      }
      const tz = (new Date().getTimezoneOffset() * 60).toString(10)
      const blob = new Blob([JSON.stringify({
        value: body
      })], headers)
      window.navigator.sendBeacon(PlayerHttp.instance().requestAPIURL(`player/stat/json/content/play-list?tz=${tz}&token=${encodeURIComponent(process.env.VUE_APP_PLAYER_API_TOKEN)}`), blob)
    }
  }
}
