import { LoginToken } from '@/player/model/json'

const namespace = 'meta/'

const LOCALE = namespace + 'LOCALE'
const SUBDOMAIN = namespace + 'SUBDOMAIN'
const TEMPLATE = namespace + 'TEMPLATE'
const TEMPLATERELOAD = namespace + 'TEMPLATERELOAD'
const LOGO = namespace + 'LOGO'
const BTOBLOGO = namespace + 'BTOBLOGO'
const BOARD = namespace + 'BOARD'
const NEEDMAINLOGIN = namespace + 'NEEDMAINLOGIN'
const CRAWLERERROR = namespace + 'CRAWLERERROR'
const PAGEUID = namespace + 'PAGEUID'

export class LocaleActionPayload {
  public static type = LOCALE
  public readonly type = LOCALE

  constructor(readonly locale: string) {
  }
}

export class SubdomainActionPayload {
  public static type = SUBDOMAIN
  public readonly type = SUBDOMAIN

  constructor(readonly subdomain: string, readonly isCustom: boolean) {
  }
}

export class TemplateActionPayload {
  public static type = TEMPLATE
  public readonly type = TEMPLATE

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly template: any) {
  }
}

export class TemplateReloadActionPayload {
  public static type = TEMPLATERELOAD
  public readonly type = TEMPLATERELOAD
}

export class LogoActionPayload {
  public static type = LOGO
  public readonly type = LOGO

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly logo: any) {
  }
}

export class BtoBLogoActionPayload {
  public static type = BTOBLOGO
  public readonly type = BTOBLOGO

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly logo: any) {
  }
}

export class BoardActionPayload {
  public static type = BOARD
  public readonly type = BOARD

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly board: any) {
  }
}

export class NeedMainLoginActionPayload {
  public static type = NEEDMAINLOGIN
  public readonly type = NEEDMAINLOGIN

  constructor(readonly token: LoginToken | null) {
  }
}

export class CrawlerErrorActionPayload {
  public static type = CRAWLERERROR
  public readonly type = CRAWLERERROR

  constructor(readonly isError: boolean) {
  }
}

export class PageUIDActionPayload {
  public static type = PAGEUID
  public readonly type = PAGEUID

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(readonly pageType: number, readonly pageUID: string) {
  }
}
