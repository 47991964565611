import {
  PlayerAppBannerActionPayload,
  PlayerCatchHeaderLoginActionPayload,
  PlayerChannelIOActionPayload,
  PlayerLocaleActionPayload,
  PlayerLogoActionPayload,
  PlayerNotificationCountActionPayload,
  PlayerShakaPolyfillActionPayload,
  PlayerSubdomainActionPayload,
  PlayerTemplateActionPayload,
  PlayerUpdateNotificationCountActionPayload
} from '@/store/player/meta/action'
import { LogoData, TemplateData } from '@/player/model/json'
import { DefaultAppIcon, DefaultAppName } from '@/model/constant'
import { ChannelIOService } from '@/player/service/channel.io.service'
import { state as AuthState } from '../auth/index'

export type State = {
  locale: string;
  subdomain: string;
  customDomain: string;
  appName: string;
  appIcon: string;
  staticPath?: string;
  senderEmail: string;
  isBtoB: boolean;
  template: TemplateData | null;
  logo: LogoData | null;
  shakaPolyfill: boolean;
  isAdult: boolean;
  appBanner: (() => void) | null;
  catchHeaderLogin: (() => void) | null;
  notificationCount: number;
  notificationCountDate: Date;
  thirdOAuthType: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thirdOAuth: any | null;
  thirdOAuthHasPlan: boolean;
}

export const state: State = {
  locale: 'ja',
  subdomain: '',
  customDomain: '',
  appName: DefaultAppName,
  appIcon: DefaultAppIcon,
  senderEmail: 'contact@liteview.jp',
  isBtoB: false,
  template: null,
  logo: null,
  shakaPolyfill: false,
  isAdult: false,
  appBanner: null,
  catchHeaderLogin: null,
  notificationCount: 0,
  notificationCountDate: new Date(0),
  thirdOAuthType: null,
  thirdOAuth: null,
  thirdOAuthHasPlan: false
}

try {
  if (window.localStorage) {
    state.subdomain = window.localStorage.getItem('subdomain') ?? ''
  }
} catch (e) {
  console.error(e)
}

const mutations = {
  [PlayerLocaleActionPayload.type](state: State, payload: PlayerLocaleActionPayload) {
    state.locale = payload.locale
  },
  [PlayerSubdomainActionPayload.type](state: State, payload: PlayerSubdomainActionPayload) {
    state.subdomain = payload.subdomain
  },
  [PlayerTemplateActionPayload.type](state: State, payload: PlayerTemplateActionPayload) {
    state.template = payload.template
    state.customDomain = payload.template.customDomain || ''
    state.senderEmail = payload.template.senderEmail || 'contact@liteview.jp'
    state.isAdult = !!payload.template.ageRating && payload.template.ageRating >= 18
    state.thirdOAuthType = payload.template.thirdOAuthType ?? null
    state.thirdOAuth = payload.template.thirdOAuth ?? null
    if (state.thirdOAuth) {
      state.thirdOAuthHasPlan = state.thirdOAuth.hasPlan
    } else {
      state.thirdOAuthHasPlan = false
    }
    const tmp = document.createElement('DIV')
    tmp.innerHTML = payload.template.name
    document.title = tmp.textContent || tmp.innerText || ''
  },
  [PlayerLogoActionPayload.type](state: State, payload: PlayerLogoActionPayload) {
    state.logo = payload.logo
    if (payload.logo && payload.logo.appName) {
      state.appName = payload.logo.appName
      state.isBtoB = true
      state.staticPath = payload.logo.staticPath
    } else {
      state.appName = DefaultAppName
      state.isBtoB = false
      state.staticPath = undefined
    }
    if (payload.logo && payload.logo.appIcon) {
      state.appIcon = payload.logo.appIcon
    } else {
      state.appIcon = DefaultAppIcon
    }
  },
  [PlayerAppBannerActionPayload.type](state: State, payload: PlayerAppBannerActionPayload) {
    state.appBanner = payload.appBanner
  },
  [PlayerShakaPolyfillActionPayload.type](state: State, payload: PlayerShakaPolyfillActionPayload) {
    state.shakaPolyfill = true
  },
  [PlayerCatchHeaderLoginActionPayload.type](state: State, payload: PlayerCatchHeaderLoginActionPayload) {
    state.catchHeaderLogin = payload.fn
  },
  [PlayerNotificationCountActionPayload.type](state: State, payload: PlayerNotificationCountActionPayload) {
    state.notificationCount = payload.count
    state.notificationCountDate = payload.date
  },
  [PlayerUpdateNotificationCountActionPayload.type](state: State, payload: PlayerUpdateNotificationCountActionPayload) {
  },
  [PlayerChannelIOActionPayload.type](state: State, payload: PlayerChannelIOActionPayload) {
    if (payload.key && payload.key.key) {
      ChannelIOService.init(payload.key.key, payload.key.policy)
      setTimeout(() => {
        if (!AuthState.user) {
          ChannelIOService.bindUser()
        }
      }, 2000)
    } else {
      ChannelIOService.shutdown()
    }
  }
}

export default {
  state,
  mutations
}
