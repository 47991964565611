/* eslint-disable @typescript-eslint/no-explicit-any */
import app from '@/main'
import { catchError, map, takeUntil } from 'rxjs/operators'
import { PlayerApiService } from '@/player/service/api.service'
import { of, Subject } from 'rxjs'
import AlertModal from '@/components/modal/AlertModal.vue'
import { PlayerUIService } from '@/player/service/ui.service'
import { State } from '@/store'
import { Vue } from 'vue-property-decorator'
import { AsyncComponent, ComponentOptions } from 'vue'

const Config = {
  channelIOInitialized: false,
  stashedUser: undefined as (undefined | [string, any]),
  hideButton: true
}

export class ChannelIOService {
  private readonly _pluginKey: string
  private readonly _policy: number
  private _unsubscribe$ = new Subject()
  private _dummyIconElement: HTMLElement

  booting = false
  booted = false

  constructor(pluginKey: string, policy: number) {
    this._pluginKey = pluginKey
    this._policy = policy
    const el = document.createElement('div')
    el.style.display = 'none'
    el.style.position = 'fixed'
    el.style['z-index'] = 1
    el.style.bottom = '0'
    el.style.right = '4px'
    el.style.width = '100px'
    el.style.height = '100px'
    el.style.cursor = 'pointer'
    const icon = document.createElement('img')
    icon.style.width = '100%'
    icon.style.height = '100%'
    icon.src = 'img/icons/channel-io-icon.svg'
    el.appendChild(icon)
    el.addEventListener('click', () => {
      let msg = ''
      switch (this._policy) {
        case 1:
          msg = app.$tc('player.text.channelio.need_plan')
          break
        case 2:
          msg = app.$tc('player.text.channelio.need_login')
          break
        default:
          break
      }
      if (msg) {
        const name = AlertModal.NAME + '-' + Math.random()
        this._showAlert(name, AlertModal, {
          name,
          message: msg
        }, undefined, {
          closed: () => {
            const state = app.$store?.state as State
            switch (this._policy) {
              case 1:
              case 2:
                PlayerUIService.joinModal(this._showAlert.bind(this),
                  true,
                  state.playerMeta.template,
                  undefined, undefined)
                  .subscribe(_ => {
                  }, e => {
                    app.$log?.error(e)
                    const name2 = AlertModal.NAME + '-' + Math.random()
                    this._showAlert(name2, AlertModal, {
                      error: e
                    })
                  })
                break
              default:
                break
            }
          }
        })
      }
    })
    this._dummyIconElement = el
    document.body.appendChild(el)
    this.loadScript()
  }

  private _showAlert(
    name: string,
    component: typeof Vue | ComponentOptions<Vue> | AsyncComponent,
    componentProps?: any,
    modalProps?: any,
    modalEvents?: object) {
    componentProps = componentProps ?? {}
    componentProps.name = name
    modalProps = modalProps ?? {}
    modalProps.name = name
    modalProps.adaptive = true
    if (modalProps.scrollable === undefined) {
      modalProps.scrollable = true
    }
    if (modalProps.height === undefined) {
      modalProps.height = 'auto'
    }
    modalEvents = modalEvents ?? {}
    app.$modal?.show(component, componentProps, modalProps, modalEvents)
  }

  static init(pluginKey: string, policy: number) {
    if (channelIO && channelIO._pluginKey !== pluginKey) {
      ChannelIOService.shutdown()
    }
    if (!channelIO) {
      channelIO = new ChannelIOService(pluginKey, policy)
    }
  }

  static shutdown() {
    if (channelIO) {
      channelIO.shutdown()
      channelIO._dummyIconElement.remove()
      channelIO = null
    }
  }

  static bindUser(user?: [string, any]) {
    if (!channelIO) {
      Config.stashedUser = user
      return
    }
    Config.stashedUser = undefined
    channelIO._unsubscribe$.next()
    channelIO._unsubscribe$.complete()
    channelIO._unsubscribe$ = new Subject()
    if (channelIO.booted) {
      channelIO.shutdown()
      setTimeout(() => {
        ChannelIOService.bindUser(user)
      }, 100)
      return
    }
    let hideButton = of(true)
    switch (channelIO._policy) {
      case 1:
        // Member
        hideButton = PlayerApiService.checkJoin()
          .pipe(map(b => !b && !(app.$store?.getters.isCompanyOwner ?? false)), catchError(e => of(true)))
        break
      case 2:
        // Public
        hideButton = of(!user)
        break
      case 3:
        // Anonymous
        hideButton = of(false)
        break
    }
    hideButton
      .pipe(takeUntil(channelIO._unsubscribe$))
      .subscribe(hide => {
        if (!channelIO) {
          return
        }
        if (hide) {
          if (channelIO.booted) {
            channelIO.shutdown()
          }
          channelIO._dummyIconElement.style.display = 'block'
          return
        }
        channelIO._dummyIconElement.style.display = 'hidden'
        if (user) {
          channelIO.boot({
            pluginKey: channelIO._pluginKey,
            hideChannelButtonOnBoot: true,
            memberId: user[1].uid,
            profile: {
              name: user[1].userNickname,
              email: user[1].userId
            }
          })
        } else {
          channelIO.boot({
            pluginKey: channelIO._pluginKey,
            hideChannelButtonOnBoot: true
          })
        }
      })
  }

  loadScript() {
    const w = window as any
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {
      })('ChannelIO script included twice.')
    }
    const ch = function () {
      // eslint-disable-next-line prefer-rest-params
      ch.c(arguments)
    }
    ch.q = []
    ch.c = function (args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch

    if (Config.stashedUser) {
      if (channelIO) {
        ChannelIOService.bindUser(Config.stashedUser)
        Config.stashedUser = undefined
      } else {
        setTimeout(() => {
          ChannelIOService.bindUser(Config.stashedUser)
          Config.stashedUser = undefined
        }, 0)
      }
    }

    function l() {
      if (Config.channelIOInitialized) {
        return
      }
      Config.channelIOInitialized = true
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      s.charset = 'UTF-8'
      const x = document.getElementsByTagName('script')[0]
      x.parentNode?.insertBefore(s, x)
    }

    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }
  }

  boot(settings) {
    const w = window as any
    if (w.ChannelIO) {
      this.booting = true
      w.ChannelIO('boot', settings, (error, user) => {
        this.booting = false
        if (error) {
          app.$log?.error(error)
        } else {
          app.$log?.debug('boot success', user)
          this.booted = true
          if (Config.hideButton) {
            this.hideChannelButton()
          } else {
            this.showChannelButton()
          }
        }
      })
    }
  }

  shutdown() {
    const w = window as any
    if (w.ChannelIO && this.booted) {
      w.ChannelIO('shutdown')
      this.booted = false
      this.booting = false
    }
  }

  static showChannelButton() {
    Config.hideButton = false
    if (channelIO) {
      channelIO.showChannelButton()
    }
  }

  showChannelButton() {
    Config.hideButton = false
    const w = window as any
    if (w.ChannelIO && this.booted) {
      w.ChannelIO('showChannelButton')
    }
  }

  static hideChannelButton() {
    Config.hideButton = true
    if (channelIO) {
      channelIO.hideChannelButton()
    }
  }

  hideChannelButton() {
    Config.hideButton = true
    const w = window as any
    if (w.ChannelIO && this.booted) {
      w.ChannelIO('hideChannelButton')
    }
  }
}

export let channelIO: ChannelIOService | null = null
