import {
  BoardActionPayload,
  BtoBLogoActionPayload,
  CrawlerErrorActionPayload,
  LocaleActionPayload,
  LogoActionPayload,
  NeedMainLoginActionPayload,
  PageUIDActionPayload,
  SubdomainActionPayload,
  TemplateActionPayload,
  TemplateReloadActionPayload
} from '@/store/meta/action'
import { BrowserService } from '@/service/browser.service'
import { DefaultAppName } from '@/model/constant'
import { LoginToken } from '@/player/model/json'
import { GTAGService } from '@/service/gtag.service'
import { SiteDesignData, SiteDesignPageType, SiteDesignSectionDataType, SiteDesignSectionType } from '@/model/template'
import app from '@/main'
import { RouteConfig } from 'vue-router'
import { SecurityService } from '@/player/service/security.service'

export type State = {
  locale: string;
  subdomain: string;
  customDomain: string;
  appName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logo: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  btoblogo: any | null;
  notAllowRegister: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  board: any | null;
  isCustom: boolean;
  needMainLogin: LoginToken | null;
  isCrawlerError: boolean;
  companyServiceType: number;
  lastPageUID: { [key: string]: string };
}

export const state: State = {
  locale: 'ja',
  subdomain: '',
  customDomain: '',
  appName: DefaultAppName,
  template: null,
  logo: null,
  btoblogo: null,
  notAllowRegister: false,
  board: null,
  isCustom: false,
  needMainLogin: null,
  isCrawlerError: false,
  companyServiceType: 1,
  lastPageUID: {}
}

const mutations = {
  [LocaleActionPayload.type](state: State, payload: LocaleActionPayload) {
    state.locale = payload.locale
  },
  [SubdomainActionPayload.type](state: State, payload: SubdomainActionPayload) {
    state.subdomain = payload.subdomain
    state.isCustom = payload.isCustom
  },
  [TemplateActionPayload.type](state: State, payload: TemplateActionPayload) {
    state.template = payload.template
    state.customDomain = payload.template.customDomain || ''
    state.companyServiceType = payload.template.serviceType || 1
    const tmp = document.createElement('DIV')
    tmp.innerHTML = payload.template.name
    document.title = tmp.textContent || tmp.innerText || ''

    BrowserService.favicon(payload.template)

    const disable = payload.template.customDomain && payload.template.customDomain !== window.location.hostname
    BrowserService.metaTag(payload.template, disable, state.isCustom)

    try {
      const customMetaList = document.querySelectorAll('meta[data-meta="custom"]')
      if (customMetaList) {
        for (let i = customMetaList.length - 1; i >= 0; --i) {
          customMetaList[i].remove()
        }
      }
      if (payload.template.customMetaTag) {
        const el = document.createElement('head')
        el.innerHTML = payload.template.customMetaTag
        if (el.childNodes) {
          for (let i = el.childNodes.length - 1; i >= 0; --i) {
            const meta = el.childNodes[i] as HTMLMetaElement
            if (meta.setAttribute) {
              meta.setAttribute('data-meta', 'custom')
            }
            document.head.appendChild(meta)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }

    const gtmId = payload.template.gtmId
    GTAGService.load(gtmId, state.subdomain)

    try {
      const templateData = SiteDesignData.from(payload.template.content)
      const routes: Array<RouteConfig> = []
      let hasWorkflow = false
      for (const page of templateData.pages) {
        if (page.link && SiteDesignPageType.reservedLinks().indexOf(page.link) < 0) {
          switch (page.type) {
            case SiteDesignPageType.Home:
            case SiteDesignPageType.Login:
            case SiteDesignPageType.Logout:
            case SiteDesignPageType.Contact:
            case SiteDesignPageType.Enquete:
            case SiteDesignPageType.MyPage:
              break
            case SiteDesignPageType.ContentList:
              routes.push({
                path: `/${page.link}`,
                props: {
                  pageUID: page.uid
                },
                meta: {
                  pageUID: page.uid,
                  pageType: page.type
                },
                component: () => import(/* webpackChunkName: "programs" */ '@/views/Programs.vue')
              })
              break
            case SiteDesignPageType.Chatroom:
              routes.push({
                path: `/${page.link}`,
                props: {
                  pageUID: page.uid
                },
                meta: {
                  pageUID: page.uid,
                  pageType: page.type
                },
                component: () => import(/* webpackChunkName: "chatroom" */ '@/views/Chatroom.vue'),
                children: [
                  {
                    path: 'chat/:uid',
                    props: true,
                    meta: {
                      dm: true,
                      admin: false,
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
                  },
                  {
                    path: 'chat-admin/:uid',
                    props: true,
                    meta: {
                      dm: true,
                      admin: true,
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
                  },
                  {
                    path: 'group/:uid',
                    props: true,
                    meta: {
                      dm: false,
                      admin: true,
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
                  }
                ]
              })
              break
            case SiteDesignPageType.ForumList:
            case SiteDesignPageType.Forum:
              routes.push({
                path: `/${page.link}`,
                props: {
                  pageUID: page.uid
                },
                meta: {
                  pageUID: page.uid,
                  pageType: page.type
                },
                component: () => import(/* webpackChunkName: "forum" */ '@/views/Forum.vue'),
                children: [
                  {
                    path: '/',
                    props: true,
                    meta: {
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    components: {
                      nav: () => import(/* webpackChunkName: "forumlist" */ '@/views/ForumList.vue'),
                      body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
                    }
                  },
                  {
                    path: ':uid',
                    props: true,
                    meta: {
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    components: {
                      nav: () => import(/* webpackChunkName: "forumtopiclist" */ '@/views/ForumTopicList.vue'),
                      body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
                    }
                  },
                  {
                    path: ':uid/:tuid',
                    props: true,
                    meta: {
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    components: {
                      nav: () => import(/* webpackChunkName: "forumtopiclist" */ '@/views/ForumTopicList.vue'),
                      body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
                    }
                  }
                ]
              })
              break
            case SiteDesignPageType.UserGroup:
              routes.push({
                path: `/${page.link}`,
                props: {
                  pageUID: page.uid
                },
                meta: {
                  pageUID: page.uid,
                  pageType: page.type
                },
                component: () => import(/* webpackChunkName: "group" */ '@/views/Group.vue'),
                children: [
                  {
                    path: '',
                    props: true,
                    meta: {
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    component: () => import(/* webpackChunkName: "groupList" */ '@/views/GroupList.vue')
                  },
                  {
                    path: ':uid',
                    props: true,
                    meta: {
                      pageUID: page.uid,
                      pageType: page.type
                    },
                    component: () => import(/* webpackChunkName: "groupList" */ '@/views/GroupList.vue')
                  }
                ]
              })
              break
            case SiteDesignPageType.Custom:
            case SiteDesignPageType.Webview:
            case SiteDesignPageType.Search:
            case SiteDesignPageType.UserSearch:
            case SiteDesignPageType.Workflow:
            case SiteDesignPageType.WorkflowData:
            case SiteDesignPageType.WorkflowList:
            case SiteDesignPageType.ECList:
            case SiteDesignPageType.FundingList:
            case SiteDesignPageType.TicketList:
            case SiteDesignPageType.CtaPage:
            case SiteDesignPageType.CtaPageQuick:
            case SiteDesignPageType.MobileAppGuide:
              if (page.type === SiteDesignPageType.Workflow && page.link === 'workflow') {
                hasWorkflow = true
              }
              routes.push({
                path: `/${page.link}`,
                props: {
                  pageUID: page.uid
                },
                meta: {
                  pageUID: page.uid,
                  pageType: page.type
                },
                component: () => import(/* webpackChunkName: "subpage" */ '@/views/SubPage.vue')
              })
              if (page.type === SiteDesignPageType.WorkflowData) {
                routes.push({
                  path: `/${page.link}/answer-file/:answerUID`,
                  props: true,
                  component: () => import(/* webpackChunkName: "workflowedit" */ '@/components/WorkflowFileEdit.vue')
                })
              }
              break
            case SiteDesignPageType.External: {
              for (const data of page.data) {
                if (data.type === SiteDesignSectionDataType.PageLink) {
                  const url = data.data as string || ''
                  if (url) {
                    routes.push({
                      path: `/${page.link}`,
                      props: {
                        pageUID: page.uid
                      },
                      meta: {
                        pageUID: page.uid,
                        pageType: page.type
                      },
                      beforeEnter: (from, to, next) => {
                        window.location.href = url
                      }
                    })
                  }
                }
              }
              break
            }
          }
        }
      }
      if (!hasWorkflow) {
        routes.push({
          path: 'workflow',
          meta: {
            pageType: SiteDesignPageType.Workflow
          },
          component: () => import(/* webpackChunkName: "workflow" */ '@/components/page-template/WorkflowPageTemplate.vue')
        })
      }
      if (routes.length) {
        const router = app.$router
        if (router) {
          for (const route of routes) {
            router.addRoute('root', route)
          }
        }
      }
      for (const section of templateData.sections) {
        if (section.type === SiteDesignSectionType.Basic) {
          for (const data of section.data) {
            switch (data.type) {
              case SiteDesignSectionDataType.CustomCodeHead: {
                const existList = document.head.querySelectorAll('[data-lid="custom-head"]')
                if (data.data && !existList.length) {
                  try {
                    const headTag = document.createElement('head')
                    headTag.innerHTML = data.data as string || ''
                    for (const node of headTag.childNodes) {
                      const el = node as HTMLElement
                      if (el.nodeName.toLowerCase() === 'script') {
                        const exist = el as HTMLScriptElement
                        const script = document.createElement('script')
                        script.type = exist.type
                        if (exist.src) {
                          script.src = exist.src
                        } else {
                          script.textContent = exist.textContent
                        }
                        script.onload = exist.onload
                        script.onerror = exist.onerror
                        script.setAttribute('data-lid', 'custom-head')
                        document.head.appendChild(script)
                      } else if (['style', 'link', 'meta', 'script', 'noscript'].indexOf(el.nodeName.toLowerCase()) >= 0) {
                        el.setAttribute('data-lid', 'custom-head')
                        document.head.appendChild(el.cloneNode(true))
                      }
                    }
                  } catch (e) {
                    console.error(e)
                  }
                }
                break
              }
            }
          }
          break
        }
      }
    } catch (e) {
      console.error(e)
    }
  },
  [TemplateReloadActionPayload.type](state: State, payload: TemplateReloadActionPayload) {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = state.template.name
    document.title = tmp.textContent || tmp.innerText || ''

    const disable = state.template.customDomain && state.template.customDomain !== window.location.hostname
    BrowserService.metaTag(state.template, disable, state.isCustom)
  },
  [LogoActionPayload.type](state: State, payload: LogoActionPayload) {
    state.logo = payload.logo
    if (payload.logo && payload.logo.appName) {
      state.appName = payload.logo.appName
    } else {
      state.appName = DefaultAppName
    }
  },
  [BtoBLogoActionPayload.type](state: State, payload: BtoBLogoActionPayload) {
    state.btoblogo = payload.logo
    state.notAllowRegister = payload.logo.noPlan ?? false
  },
  [BoardActionPayload.type](state: State, payload: BoardActionPayload) {
    state.board = payload.board
    SecurityService.getInstance().companyUID = payload.board.companyUID || ''
  },
  [NeedMainLoginActionPayload.type](state: State, payload: NeedMainLoginActionPayload) {
    state.needMainLogin = payload.token
  },
  [CrawlerErrorActionPayload.type](state: State, payload: CrawlerErrorActionPayload) {
    state.isCrawlerError = payload.isError
  },
  [PageUIDActionPayload.type](state: State, payload: PageUIDActionPayload) {
    state.lastPageUID[payload.pageType.toString(10)] = payload.pageUID
    app.$log?.debug(`pageUID: ${payload.pageType}, ${payload.pageUID}`)
  }
}

export default {
  state,
  mutations
}
