





















import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import CloseIcon from 'vue-material-design-icons/Close.vue'

@Component({
  components: {
    CloseIcon
  }
})
export default class LoginLineModal extends AbstractModalVue {
  public static NAME = 'login-line-modal'

  @Prop() callback!: () => void

  done() {
    this.hide()
    this.callback()
  }

  hide() {
    super.hide()
  }
}
