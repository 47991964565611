


































import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import InformationIcon from 'vue-material-design-icons/Information.vue'

@Component({
  components: {
    CloseIcon,
    InformationIcon
  }
})
export default class JoinMailInfoModal extends AbstractModalVue {
  public static NAME = 'join-mail-info-modal'

  @Prop() domain!: string
  @Prop() onCheck!: () => void

  hide() {
    super.hide()
  }

  done() {
    this.hide()
    this.onCheck()
  }
}
