import {
  CustomDomainLoginActionPayload,
  PlayerAuthBlockPayload,
  PlayerAuthFailedPayload,
  PlayerCPActionPayload,
  PlayerLoginActionPayload,
  PlayerLogoutActionPayload
} from '@/store/player/auth/action'
import { Proto } from '@/player/model/proto'
import app from '@/main'
import { LoginToken } from '@/player/model/json'
import { ChannelIOService } from '@/player/service/channel.io.service'
import { APIService } from '@/service/api.service'
import store from '@/store'
import { BoardActionPayload } from '@/store/meta/action'
import { SecurityService } from '@/player/service/security.service'

export type State = {
  isInitialized: boolean;
  loggedIn: boolean;
  uuid?: string;
  user?: Proto.IUser;
  loginUserCP: string | null;
  blocked: boolean;
  domainLogin: LoginToken | null;
}

export const state: State = {
  isInitialized: false,
  loggedIn: false,
  uuid: undefined,
  user: undefined,
  loginUserCP: null,
  blocked: false,
  domainLogin: null
}

const mutations = {
  [PlayerLoginActionPayload.type](state: State, payload: PlayerLoginActionPayload) {
    state.isInitialized = state.loggedIn = true
    state.uuid = payload.uuid
    state.user = payload.user
    app.$log.info(`login: ${payload.uuid}`)
    ChannelIOService.bindUser([payload.uuid, payload.user])
    SecurityService.getInstance().logined = true

    const domain = store.state.playerMeta.subdomain
    if (domain) {
      const service = new APIService(domain)
      service.communityBoard()
        .then(board => {
          store.commit(new BoardActionPayload(board))
        })
        .catch(e => {
          app.$log?.error(e)
        })
    }
  },
  [PlayerLogoutActionPayload.type](state: State) {
    state.loggedIn = false
    state.uuid = undefined
    state.user = undefined
    state.loginUserCP = null
    state.blocked = false
    ChannelIOService.bindUser(undefined)
    SecurityService.getInstance().logined = false
  },
  [PlayerCPActionPayload.type](state: State, payload: PlayerCPActionPayload) {
    state.loginUserCP = payload.companyUID
  },
  [PlayerAuthFailedPayload.type](state: State) {
    state.isInitialized = true
  },
  [PlayerAuthBlockPayload.type](state: State, payload: PlayerAuthBlockPayload) {
    state.blocked = payload.blocked
  },
  [CustomDomainLoginActionPayload.type](state: State, payload: CustomDomainLoginActionPayload) {
    state.domainLogin = payload.token
  }
}

export default {
  state,
  mutations
}
