


































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import { catchError, defaultIfEmpty, filter, finalize, map, mergeMap, takeUntil } from 'rxjs/operators'
import AlertModal from '@/player/components/modal/AlertModal.vue'
import { ErrorCode, MessageError } from '@/player/model/error'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import { PlayerApiService } from '@/player/service/api.service'
import { Proto } from '@/player/model/proto'
import ForgotModal from '@/player/components/modal/ForgotModal.vue'
import { fromPromise } from 'rxjs/internal-compatibility'
import { PlayerStoreService } from '@/player/service/store.service'
import { StoreKey } from '@/player/model/constant'
import LoginLineModal from '@/player/components/modal/LoginLineModal.vue'
import { State } from '@/store'
import app from '@/main'
import { EMPTY, Observable, of, throwError } from 'rxjs'
import JoinMailInfoModal from '@/player/components/modal/JoinMailInfoModal.vue'
import { spamMailList } from '@/model/constant'
import EyeIcon from 'vue-material-design-icons/Eye.vue'
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue'
import { BrowserService } from '@/service/browser.service'
import JoinOrixModal from '@/player/components/modal/JoinOrixModal.vue'
import { BTooltip } from 'bootstrap-vue'
import HelpCircleIcon from 'vue-material-design-icons/HelpCircle.vue'
import JoinKintoneModal from '@/player/components/modal/JoinKintoneModal.vue'
import ConfirmModal from '@/player/components/modal/ConfirmModal.vue'
import { APIService } from '@/service/api.service'
import PrivateCommunityModal from '@/player/components/modal/PrivateCommunityModal.vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let AppleID: any

@Component({
  components: {
    BTooltip,
    CloseIcon,
    EyeIcon,
    EyeOffIcon,
    HelpCircleIcon
  }
})
export default class JoinModal extends AbstractModalVue {
  public static NAME = 'join-modal'

  @Prop() private title!: string | undefined
  @Prop() private message!: string | undefined
  @Prop() private error!: Error | undefined
  @Prop() private planUID!: string
  @Prop() private playlistUID!: string
  @Prop() private playlistUIDForJoinCheck!: string
  @Prop() private contentUID!: string
  @Prop() private planName!: string
  @Prop() private loginMode!: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() private joinParams?: any
  @Prop() private showSignup!: boolean

  displayLogin = true

  link = {
    userPolicy: process.env.VUE_APP_USER_POLICY,
    privatePolicy: process.env.VUE_APP_PRIVATE_POLICY,
    contact: 'contact@liteview.jp',
    contactDomain: 'liteview.jp'
  }

  submitting = false
  login = {
    email: '',
    password: ''
  }

  loginFocused = {
    email: false,
    password: false
  }

  email = ''
  agree = false
  recaptchaCode = ''
  emailFocus = false
  recaptchaFocus = false
  agreeFocus = false
  isCarrierMail = false
  carrierMailChecked = false
  loginErrors = {
    email: '',
    password: '',
    validated: false
  }

  errors = {
    email: '',
    recaptcha: false,
    validated: false
  }

  kintoneId = ''
  kintoneUniqueId = ''
  thirdKintoneErrors = {
    kintoneId: '',
    uniqueId: '',
    validated: false
  }

  thirdKintoneFocused = {
    kintoneId: false,
    uniqueId: false
  }

  submitted = {
    login: false,
    signup: false,
    kintone: false
  }

  $refs!: {
    orderRecaptcha: HTMLElement;
  }

  btobName = ''
  btobLogo = ''
  onlyFreePlan = false
  mailFilterDomainList: string[] = []

  visiblePasswd = false

  mounted() {
    // ScriptService.loadRecaptcha()
    //   .subscribe(() => {
    //     this.initRecaptcha()
    //   }, e => {
    //     this.showAlert(e)
    //   })
  }

  created() {
    Promise.resolve((this.$store.state as State).meta.btoblogo)
      .then(template => {
        if (template.name) {
          this.btobName = template.name
          if (template.icon) {
            this.btobLogo = template.icon.thumbnailImageURL
          }
        }
      })
    Promise.resolve((this.$store.state as State).meta.template)
      .then(template => {
        this.onlyFreePlan = template && template.onlyFreePlan
      })
    try {
      const staticPath = (this.$store.state as State).playerMeta.staticPath
      if (staticPath) {
        this.link.userPolicy = `https://liteview.jp/static/${staticPath}/user_policy.html`
        this.link.privatePolicy = `https://liteview.jp/static/${staticPath}/privacy_policy.html`
      } else {
        this.link.userPolicy = process.env.VUE_APP_USER_POLICY
        this.link.privatePolicy = process.env.VUE_APP_PRIVATE_POLICY
      }
      this.link.contact = (this.$store.state as State).playerMeta.senderEmail
      const split = this.link.contact.split('@')
      if (split.length > 1) {
        this.link.contactDomain = split[1]
      } else {
        this.link.contactDomain = 'liteview.jp'
      }
    } catch (e) {
      this.$log.error(e)
    }

    const domain = (this.$store.state as State).meta.subdomain
    const service = new APIService(domain)
    service.maliFilterDomainList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(domainList => {
        this.mailFilterDomainList = domainList ?? []
      }, e => {
        this.$log.error(e)
      })

    if (this.showSignup) {
      this.toggleDisplayLogin()
    }
  }

  initRecaptcha(): void {
    if (!this.$refs.orderRecaptcha) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$refs.orderRecaptcha.dataset.widget = (window as any).grecaptcha.enterprise.render(this.$refs.orderRecaptcha, {
      sitekey: '6LfDGF8bAAAAAFBZRXIOIIWT6LXdViqqVMHcwuTb',
      theme: 'light',
      callback: () => {
        this.recaptchaFocus = true
        this.errors.recaptcha = false
        this.validateForm(false)
      }
    })
  }

  resetRecaptcha(): void {
    // if (!this.$refs.orderRecaptcha) {
    //   return
    // }
    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // (window as any).grecaptcha.enterprise.reset(this.$refs.orderRecaptcha)
  }

  openThirdRegister() {
    if (this.thirdOAuthType) {
      this.$Progress.start()
      PlayerApiService.thirdOAuthRegisterURL(this.thirdOAuthType)
        .pipe(takeUntil(this.unsubscribe$), finalize(() => this.$Progress.finish()))
        .subscribe(url => {
          if (!BrowserService.isMobileDevice()) {
            window.open(url, '_blank')
          } else {
            window.location.href = url
          }
        }, e => {
          this.$log.error(e)
          this.showAlert(e)
        })
    }
  }

  toggleDisplayLogin() {
    if (this.thirdOAuthType && this.thirdOAuthType !== 10001 && this.thirdOAuthType !== 10002) {
      if (this.displayLogin) {
        this.openThirdRegister()
      }
      return
    }
    this.displayLogin = !this.displayLogin
  }

  get thirdOAuthType(): number | null {
    return (this.$store.state as State).playerMeta.thirdOAuthType
  }

  get notAllowRegister(): boolean {
    return (this.$store.state as State).meta.notAllowRegister
  }

  get allowMemberId(): boolean {
    return !!this.thirdOAuthType
  }

  focus(key: string) {
    this.loginFocused[key] = true
  }

  validateLoginForm(showError: boolean) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const email = this.login.email && this.login.email.length && (this.allowMemberId || re.test(this.login.email)) ? '' : this.$tc('player.error.msg.email_required')
    const password = this.login.password && this.login.password.length ? '' : this.$tc('player.error.msg.password_required')
    this.loginErrors.validated = !email && !password
    if (!this.errors.validated) {
      this.errors.email = ''
    }
    if (showError && this.submitted.login) {
      if (this.loginFocused.email) {
        this.loginErrors.email = email
      }
      if (this.loginFocused.password) {
        this.loginErrors.password = password
      }
    } else {
      if (!email) {
        this.loginErrors.email = email
      }
      if (!password) {
        this.loginErrors.password = password
      }
    }
  }

  validateForm(showError: boolean) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let email = this.email && this.email.length && re.test(this.email) ? '' : this.$tc('player.error.msg.email_required')
    if (!email && this.email.indexOf('+') >= 0 && !this.email.endsWith('@pulit.jp') && !this.email.endsWith('@liteview.jp')) {
      email = this.$tc('player.error.msg.email_plus')
    }
    this.errors.recaptcha = false
    // const widgetId = this.$refs.orderRecaptcha.dataset?.widget
    // if (!widgetId) {
    //   this.errors.recaptcha = true
    // }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const res = (window as any).grecaptcha.enterprise.getResponse(widgetId)
    // if (!res) {
    //   this.errors.recaptcha = true
    // }
    // this.recaptchaCode = res
    // this.$log.debug(res)
    this.errors.validated = !email && this.agree && !this.errors.recaptcha
    if (!this.loginErrors.validated) {
      this.loginErrors.email = ''
      this.loginErrors.password = ''
    }
    this.isCarrierMail = !email && !!this.email && spamMailList.filter(domain => this.email.endsWith(domain)).length > 0
    if (showError && this.submitted.signup) {
      if (this.emailFocus) {
        this.errors.email = email
      }
    } else {
      if (!email) {
        this.errors.email = email
      }
    }
  }

  validateKintoneForm(showError: boolean) {
    const kintoneId = this.kintoneId && this.kintoneId.length ? '' : this.$tc('player.error.msg.field_required', undefined, { _field_: 'Kintone ID' })
    const uniqueId = this.kintoneUniqueId && this.kintoneUniqueId.length ? '' : this.$tc('player.error.msg.field_required', undefined, { _field_: '誕生日' })
    this.thirdKintoneErrors.validated = !kintoneId && !uniqueId && this.agree
    if (!this.loginErrors.validated) {
      this.loginErrors.email = ''
      this.loginErrors.password = ''
    }
    if (showError && this.submitted.kintone) {
      if (this.thirdKintoneFocused.kintoneId) {
        this.thirdKintoneErrors.kintoneId = kintoneId
      }
      if (this.thirdKintoneFocused.uniqueId) {
        this.thirdKintoneErrors.uniqueId = uniqueId
      }
    } else {
      if (!kintoneId) {
        this.thirdKintoneErrors.kintoneId = kintoneId
      }
      if (!uniqueId) {
        this.thirdKintoneErrors.uniqueId = uniqueId
      }
    }
  }

  disableLoginErrors() {
    this.emailFocus = true
    this.submitted.login = false
    if (!this.loginErrors.validated) {
      this.loginErrors.email = ''
      this.loginErrors.password = ''
    }
  }

  disableErrors() {
    this.submitted.signup = false
    if (!this.errors.validated) {
      this.errors.email = ''
      this.errors.recaptcha = false
    }
  }

  handleLoginSubmit(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.submitted.login = true
    this.focus('email')
    this.focus('password')
    this.validateLoginForm(true)
    if (!this.loginErrors.validated) {
      return
    }

    this.$Progress.start()
    this.submitting = true
    const req = Proto.LoginRequest.create({
      userId: this.login.email,
      password: this.login.password,
      uuid: '',
      thirdOAuthType: this.thirdOAuthType
    })
    const login = fromPromise(PlayerStoreService.default()
      .getItem(StoreKey.UUID + '_' + this.login.email)
      .catch(e => {
        this.$log.error(e)
        return Promise.resolve('')
      }))
      .pipe(
        mergeMap(uuid => {
          req.uuid = uuid as string
          return PlayerApiService.login(req)
        }),
        catchError(e => {
          if (e instanceof MessageError && e.code === ErrorCode.MultipleAccount) {
            return new Observable<boolean>(subscriber => {
              const name = ConfirmModal.NAME + '-' + Math.random()
              this.showModal(name, ConfirmModal, {
                title: this.$tc('player.text.modal.login_multiple_account.title'),
                message: this.$tc('player.text.modal.login_multiple_account.message'),
                okButton: this.$tc('player.text.modal.login_multiple_account.ok'),
                cancelButton: this.$tc('player.text.modal.login_multiple_account.no'),
                cancelButtonClass: 'btn-primary',
                result: (v: boolean) => {
                  subscriber.next(!v)
                }
              }, {}, {
                closed: () => {
                  subscriber.complete()
                }
              })
            })
              .pipe(mergeMap(asUser => {
                req.asUser = Proto.NullBoolean.create({
                  has: true,
                  value: asUser
                })
                return PlayerApiService.login(req)
              }))
          } else if (e instanceof MessageError && e.code === ErrorCode.ServerLoginDomain) {
            this.openDomainListModal()
            return EMPTY
          }
          return throwError(e)
        })
      )
    if (this.loginMode || this.thirdOAuthType) {
      login
        .pipe(mergeMap(() => {
          if (this.planUID || this.playlistUID || this.contentUID) {
            return PlayerApiService.joinAvailable(this.playlistUID)
              .pipe(
                mergeMap(() => {
                  if (this.playlistUID || this.playlistUIDForJoinCheck) {
                    return PlayerApiService.playlist(this.playlistUID || this.playlistUIDForJoinCheck)
                      .pipe(map(pl => pl.anonymous ?? false), catchError(e => {
                        this.$log.error(e)
                        return of(true)
                      }))
                  }
                  return of(true)
                }),
                mergeMap((anonymous) => {
                  if (anonymous) {
                    if (this.notAllowRegister) {
                      return of('')
                    }
                    return PlayerApiService.preCheckWithUser(this.planUID, this.playlistUID, this.contentUID, undefined, this.joinParams)
                  }
                  return of('')
                })
              )
          }
          return of('')
        }))
        .subscribe((url) => {
          this.$Progress.finish()
          this.submitting = false
          this.hide()
          if (url) {
            window.location.href = url
          } else {
            app.$bvToast?.toast(this.$tc('player.text.modal.join.success_login'), {
              solid: true,
              toaster: 'b-toaster-bottom-right',
              appendToast: true,
              noCloseButton: true,
              headerClass: 'd-none',
              bodyClass: 'toaster-login',
              toastClass: 'toaster-show'
            })
          }
        }, e => {
          this.$log.error(e)
          if (!!this.thirdOAuthType && this.thirdOAuthType !== 10002 && e instanceof MessageError && e.code === ErrorCode.ServerLoginFail) {
            this.handleThirdOAuthLogin(this.login.email)
          } else {
            this.$Progress.finish()
            this.submitting = false
            this.showAlert(e)
          }
        })
      return
    }
    login
      .pipe(
        mergeMap(() => PlayerApiService.joinAvailable(this.playlistUID)),
        mergeMap(() => PlayerApiService.preCheckWithUser(this.planUID, this.playlistUID, this.contentUID, undefined, this.joinParams)),
        finalize(() => {
          this.$Progress.finish()
        })
      )
      .subscribe((url) => {
        window.location.href = url
      }, e => {
        this.submitting = false
        if (e.code === ErrorCode.ServerLoginFail) {
          if (this.btobName) {
            this.showAlert(new Error(this.$tc('player.text.modal.join.error-btob', undefined, { btob: this.btobName })))
          } else {
            this.showAlert(new Error(this.$tc('player.text.modal.join.error')))
          }
        } else {
          this.$log.error(e)
          this.showAlert(e)
          if (e.code !== ErrorCode.NoInternet && e.code !== ErrorCode.ServerInternal) {
            this.hide()
          }
        }
      })
  }

  handleThirdOAuthLogin(email: string) {
    const oauthType = this.thirdOAuthType
    if (!oauthType) {
      this.$Progress.finish()
      this.submitting = false
      return
    }
    this.$Progress.start()
    this.submitting = true
    PlayerApiService.thirdOAuthLogin(oauthType, `email=${encodeURIComponent(email)}&pass=${encodeURIComponent(this.login.password)}`)
      .pipe(
        mergeMap((response): Observable<[string, string]> => {
          switch (response.errorCode) {
            case Proto.ErrorCode.OK:
              return of([response.oauthToken, response.oauthEmail])
            case Proto.ErrorCode.NO_THIRD_USER:
              if (response.message) {
                return throwError(new MessageError(ErrorCode.ServerMessage, response.message))
              }
              return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_oauth_member')))
            case Proto.ErrorCode.NO_THIRD_USER_EMAIL: {
              this.$Progress.finish()
              this.submitting = false
              return new Observable<string>(subscriber => {
                const name = JoinOrixModal.NAME + '-' + Math.random()
                this.showModal(name, JoinOrixModal, {
                  planName: this.planName,
                  result: (email: string) => {
                    this.$Progress.start()
                    this.submitting = true
                    subscriber.next(email)
                  }
                }, {
                  clickToClose: false
                }, {
                  closed: () => {
                    subscriber.complete()
                  }
                })
              })
                .pipe(
                  mergeMap(newEmail => PlayerApiService.thirdOAuthSync(oauthType, response.tmpUID, `email=${encodeURIComponent(newEmail)}&pass=${encodeURIComponent(this.login.password)}`)),
                  mergeMap((syncRes): Observable<[string, string]> => {
                    if (syncRes.errorCode === Proto.ErrorCode.OK) {
                      return of([syncRes.oauthToken, syncRes.oauthEmail])
                    } else if (syncRes.errorCode === Proto.ErrorCode.THIRD_USER_DUPLICATE) {
                      return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.oauth_duplicated')))
                    }
                    if (syncRes.message) {
                      return throwError(new MessageError(ErrorCode.ServerMessage, syncRes.message))
                    }
                    return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_oauth_member')))
                  })
                )
            }
            case Proto.ErrorCode.NO_THIRD_REGISTER:
            case Proto.ErrorCode.NO_THIRD_SYNC:
              return PlayerApiService.thirdOAuthSync(oauthType, response.tmpUID, `email=${encodeURIComponent(email)}&pass=${encodeURIComponent(this.login.password)}`)
                .pipe(mergeMap((syncRes): Observable<[string, string]> => {
                  if (syncRes.errorCode === Proto.ErrorCode.OK) {
                    return of([syncRes.oauthToken, syncRes.oauthEmail])
                  }
                  if (syncRes.message) {
                    return throwError(new MessageError(ErrorCode.ServerMessage, syncRes.message))
                  }
                  return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_oauth_member')))
                }))
          }
          if (response.message) {
            return throwError(new MessageError(ErrorCode.ServerMessage, response.message))
          }
          return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_oauth_member')))
        }),
        mergeMap(tokens => {
          const oauthToken = tokens[0]
          const oauthEmail = tokens[1]
          const req = Proto.LoginRequest.create({
            userId: oauthEmail,
            jwt: oauthToken
          })
          return fromPromise(PlayerStoreService.default()
            .getItem(StoreKey.UUID + '_' + oauthEmail)
            .catch(e => {
              this.$log.error(e)
              return Promise.resolve('')
            }))
            .pipe(
              mergeMap(uuid => {
                req.uuid = uuid as string
                return PlayerApiService.login(req)
              })
            )
        }),
        mergeMap(() => {
          if (this.planUID || this.playlistUID || this.contentUID) {
            if (this.notAllowRegister) {
              return of('')
            }
            return PlayerApiService.joinAvailable(this.playlistUID)
              .pipe(mergeMap(() => PlayerApiService.preCheckWithUser(this.planUID, this.playlistUID, this.contentUID, undefined, this.joinParams)))
          }
          return of('')
        }),
        finalize(() => {
          this.$Progress.finish()
          this.submitting = false
        })
      )
      .subscribe((url) => {
        this.hide()
        if (url) {
          window.location.href = url
        } else {
          app.$bvToast?.toast(this.$tc('player.text.modal.join.success_login'), {
            solid: true,
            toaster: 'b-toaster-bottom-right',
            appendToast: true,
            noCloseButton: true,
            headerClass: 'd-none',
            bodyClass: 'toaster-login',
            toastClass: 'toaster-show'
          })
        }
      }, e => {
        this.$log.error(e)
        this.showAlert(e)
      })
  }

  handleSubmit(event?: Event) {
    event?.preventDefault()
    event?.stopPropagation()

    this.submitted.signup = true
    this.agreeFocus = true
    this.emailFocus = true
    this.recaptchaFocus = true
    this.validateForm(true)
    if (!this.errors.validated) {
      return
    }

    if (this.email.indexOf('+') >= 0 && !this.email.endsWith('@pulit.jp') && !this.email.endsWith('@liteview.jp')) {
      this.showAlertMessage(this.$tc('player.error.msg.email_plus'))
      return
    }

    if (this.isCarrierMail && !this.carrierMailChecked) {
      const name = JoinMailInfoModal.NAME + '-' + Math.random()
      this.showModal(name, JoinMailInfoModal, {
        domain: this.link.contactDomain,
        onCheck: () => {
          this.carrierMailChecked = true
          this.handleSubmit()
        }
      })
      return
    }

    this.$Progress.start()
    this.submitting = true
    let checkOtherLogin = of(true)
    if (!(this.$store.state as State).playerAuth.uuid) {
      checkOtherLogin = PlayerApiService.authenticated()
        .pipe(
          map(uuid => !uuid),
          defaultIfEmpty(true),
          catchError(e => {
            this.$log.error(e)
            return of(true)
          })
        )
    }
    checkOtherLogin
      .pipe(
        filter(noLogin => {
          if (!noLogin) {
            const name = AlertModal.NAME + '-' + Math.random()
            this.showModal(name, AlertModal, {
              title: this.$tc('player.text.modal.join.login_from_other_title'),
              message: this.$tc('player.text.modal.join.login_from_other_msg')
            }, undefined, {
              closed: () => {
                window.location.reload()
              }
            })
          }
          return noLogin
        }),
        mergeMap(() => PlayerApiService.joinAvailable(this.playlistUID)),
        mergeMap(() => PlayerApiService.preRegister(this.email, this.recaptchaCode, this.planUID, this.playlistUID, this.contentUID, undefined, this.joinParams)),
        finalize(() => {
          this.$Progress.finish()
          this.submitting = false
        })
      )
      .subscribe(() => {
        const name = AlertModal.NAME + '-' + Math.random()
        this.showModal(name, AlertModal, {
          title: this.$tc('player.text.modal.join.title', undefined, { name: this.planName }),
          message: this.$tc('player.text.modal.join.success')
        }, undefined, {
          closed: () => {
            this.hide()
          }
        })
      }, e => {
        this.$log.error(e)
        this.resetRecaptcha()
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginFail) {
          this.showAlertMessage(this.$tc('player.text.modal.join.duplicate'))
        } else if (e instanceof MessageError && e.code === ErrorCode.ServerLoginDomain) {
          this.openDomainListModal()
        } else {
          this.showAlert(e)
        }
      })
  }

  oauthLogin(type: number): void {
    this.disableLoginErrors()
    this.disableErrors()

    if (type === 3) {
      const name = LoginLineModal.NAME + '-' + Math.random()
      this.showModal(name, LoginLineModal, {
        callback: () => {
          this.submitting = true
          window.location.href = PlayerApiService.loginOAuthUrl(type, window.location.href, this.planUID, this.playlistUID, this.contentUID, undefined, undefined, undefined, this.joinParams)
        }
      })
      return
    }
    this.submitting = true
    window.location.href = PlayerApiService.loginOAuthUrl(type, window.location.href, this.planUID, this.playlistUID, this.contentUID, undefined, undefined, undefined, this.joinParams)
  }

  oauthRegister(type: number): void {
    this.disableLoginErrors()
    this.disableErrors()

    let checkOtherLogin = of(true)
    if (!(this.$store.state as State).playerAuth.uuid) {
      checkOtherLogin = PlayerApiService.authenticated()
        .pipe(
          map(uuid => !uuid),
          defaultIfEmpty(true),
          catchError(e => {
            this.$log.error(e)
            return of(true)
          })
        )
    }
    checkOtherLogin = checkOtherLogin
      .pipe(
        filter(noLogin => {
          if (!noLogin) {
            const name = AlertModal.NAME + '-' + Math.random()
            this.showModal(name, AlertModal, {
              title: this.$tc('player.text.modal.join.login_from_other_title'),
              message: this.$tc('player.text.modal.join.login_from_other_msg')
            }, undefined, {
              closed: () => {
                window.location.reload()
              }
            })
          }
          return noLogin
        })
      )
    if (type === 3) {
      checkOtherLogin
        .subscribe(() => {
          const name = LoginLineModal.NAME + '-' + Math.random()
          this.showModal(name, LoginLineModal, {
            callback: () => {
              this.submitting = true
              PlayerApiService.joinAvailable(this.playlistUID)
                .subscribe(() => {
                  window.location.href = PlayerApiService.preRegisterOAuthUrl(type, this.planUID, this.playlistUID, this.contentUID, undefined, undefined, this.joinParams)
                }, e => {
                  this.submitting = false
                  this.$Progress.finish()
                  this.$log.error(e)
                  this.resetRecaptcha()
                  if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginFail) {
                    this.showAlertMessage(this.$tc('player.text.modal.join.duplicate'))
                  } else {
                    this.showAlert(e)
                  }
                })
            }
          })
        })
      return
    }
    this.submitting = true
    checkOtherLogin
      .pipe(mergeMap(() => PlayerApiService.joinAvailable(this.playlistUID)))
      .subscribe(() => {
        window.location.href = PlayerApiService.preRegisterOAuthUrl(type, this.planUID, this.playlistUID, this.contentUID, undefined, undefined, this.joinParams)
      }, e => {
        this.submitting = false
        this.$Progress.finish()
        this.$log.error(e)
        this.resetRecaptcha()
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerLoginFail) {
          this.showAlertMessage(this.$tc('player.text.modal.join.duplicate'))
        } else {
          this.showAlert(e)
        }
      })
  }

  hide() {
    super.hide()
  }

  forgot() {
    if (this.thirdOAuthType && this.thirdOAuthType !== 10002) {
      this.$Progress.start()
      PlayerApiService.thirdOAuthForgotPasswordURL(this.thirdOAuthType)
        .pipe(takeUntil(this.unsubscribe$), finalize(() => this.$Progress.finish()))
        .subscribe(url => {
          if (!BrowserService.isMobileDevice()) {
            window.open(url, '_blank')
          } else {
            window.location.href = url
          }
        }, e => {
          this.$log.error(e)
          this.showAlert(e)
        })
    } else {
      this.hide()
      const name = ForgotModal.NAME + '-' + Math.random()
      this.showModal(name, ForgotModal, {
        showContact: !!this.$store.state.meta.template?.loginPage
      })
    }
  }

  joinMailInfo() {
    const name = JoinMailInfoModal.NAME + '-' + Math.random()
    this.showModal(name, JoinMailInfoModal)
  }

  showPassword() {
    this.visiblePasswd = !this.visiblePasswd
  }

  handleKintoneSubmit(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.submitted.kintone = true
    this.agreeFocus = true
    this.thirdKintoneFocused.kintoneId = true
    this.thirdKintoneFocused.uniqueId = true
    this.validateKintoneForm(true)
    if (!this.thirdKintoneErrors.validated) {
      return
    }

    const oauthType = this.thirdOAuthType
    if (!oauthType) {
      this.submitting = false
      return
    }
    this.$Progress.start()
    this.submitting = true
    PlayerApiService.thirdOAuthLogin(oauthType, `kid=${encodeURIComponent(this.kintoneId)}&uid=${encodeURIComponent(this.kintoneUniqueId)}`)
      .pipe(
        mergeMap((response): Observable<[string, string]> => {
          switch (response.errorCode) {
            case Proto.ErrorCode.OK:
              return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.kintone_duplicated')))
            case Proto.ErrorCode.NO_THIRD_USER:
              if (response.message) {
                return throwError(new MessageError(ErrorCode.ServerMessage, response.message))
              }
              return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_kintone_member')))
            case Proto.ErrorCode.NO_THIRD_REGISTER:
            case Proto.ErrorCode.NO_THIRD_SYNC:
              this.$Progress.finish()
              this.submitting = false
              return new Observable<[string, string]>(subscriber => {
                const name = JoinKintoneModal.NAME + '-' + Math.random()
                this.showModal(name, JoinKintoneModal, {
                  planName: this.planName,
                  oauthType,
                  tmpName: response.name || '',
                  tmpUID: response.tmpUID,
                  result: (url) => {
                    this.$Progress.start()
                    this.submitting = true
                    subscriber.next(url)
                  }
                }, {
                  clickToClose: false
                }, {
                  closed: () => {
                    subscriber.complete()
                  }
                })
              })
          }
          if (response.message) {
            return throwError(new MessageError(ErrorCode.ServerMessage, response.message))
          }
          return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_kintone_member')))
        }),
        mergeMap(tokens => {
          const oauthToken = tokens[0]
          const oauthEmail = tokens[1]
          const req = Proto.LoginRequest.create({
            userId: oauthEmail,
            jwt: oauthToken
          })
          return fromPromise(PlayerStoreService.default()
            .getItem(StoreKey.UUID + '_' + oauthEmail)
            .catch(e => {
              this.$log.error(e)
              return Promise.resolve('')
            }))
            .pipe(
              mergeMap(uuid => {
                req.uuid = uuid as string
                return PlayerApiService.login(req)
              })
            )
        }),
        mergeMap(() => {
          if (this.planUID || this.playlistUID || this.contentUID) {
            return PlayerApiService.joinAvailable(this.playlistUID)
              .pipe(mergeMap(() => PlayerApiService.preCheckWithUser(this.planUID, this.playlistUID, this.contentUID, undefined, this.joinParams)))
          }
          return of('')
        }),
        finalize(() => {
          this.$Progress.finish()
          this.submitting = false
        })
      )
      .subscribe((url) => {
        this.hide()
        if (url) {
          window.location.href = url
        } else {
          app.$bvToast?.toast(this.$tc('player.text.modal.join.success_login'), {
            solid: true,
            toaster: 'b-toaster-bottom-right',
            appendToast: true,
            noCloseButton: true,
            headerClass: 'd-none',
            bodyClass: 'toaster-login',
            toastClass: 'toaster-show'
          })
        }
      }, e => {
        this.$log.error(e)
        this.showAlert(e)
      })
  }

  openDomainListModal() {
    const name = PrivateCommunityModal.NAME + '-' + Math.random()
    this.showModal(name, PrivateCommunityModal, {
      planName: this.planName,
      domainList: this.mailFilterDomainList
    })
  }
}
