import { initializeApp } from 'firebase/app'
import { FirebaseApp } from '@firebase/app'

export const firebaseApp: () => FirebaseApp = (function () {
  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'AIzaSyBSzZgNuoo5JD9DfpC-d6LXo3hN3G3-lkw',
    authDomain: 'pulit-liteviewer.firebaseapp.com',
    projectId: 'pulit-liteviewer',
    storageBucket: 'pulit-liteviewer.appspot.com',
    messagingSenderId: '362490018337',
    appId: process.env.VUE_APP_FIREBASE_ID
  }

  // Initialize Firebase
  let firebaseApp: FirebaseApp | null = null
  return () => {
    if (firebaseApp == null) {
      firebaseApp = initializeApp(firebaseConfig)
    }
    return firebaseApp
  }
})()
