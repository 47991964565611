
























































































import { Component, Prop } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import { State } from '@/store'
import JoinMailInfoModal from '@/player/components/modal/JoinMailInfoModal.vue'
import { spamMailList } from '@/model/constant'
import EyeIcon from 'vue-material-design-icons/Eye.vue'
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue'
import { PlayerApiService } from '@/player/service/api.service'
import { finalize, mergeMap } from 'rxjs/operators'
import { Observable, of, throwError } from 'rxjs'
import { Proto } from '@/player/model/proto'
import { ErrorCode, MessageError } from '@/player/model/error'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
@Component({
  components: {
    CloseIcon,
    EyeIcon,
    EyeOffIcon
  }
})
export default class JoinKintoneModal extends AbstractModalVue {
  public static NAME = 'join-kintone-modal'

  @Prop() private planName!: string
  @Prop() private oauthType!: number
  @Prop() private tmpName!: string
  @Prop() private tmpUID!: string
  @Prop() private result!: (url: [string, string]) => void

  link = {
    contact: 'contact@liteview.jp',
    contactDomain: 'liteview.jp'
  }

  login = {
    email: '',
    password: '',
    passwordConfirm: ''
  }

  loginFocused = {
    email: false,
    password: false,
    passwordConfirm: false
  }

  isCarrierMail = false
  carrierMailChecked = false
  loginErrors = {
    email: '',
    password: '',
    passwordConfirm: '',
    validated: false
  }

  submitted = {
    login: false
  }

  btobName = ''
  btobLogo = ''

  visiblePasswd = false
  submitting = false

  destroyed() {
    super.destroyed()
  }

  created() {
    Promise.resolve((this.$store.state as State).meta.btoblogo)
      .then(template => {
        if (template.name) {
          this.btobName = template.name
          if (template.icon) {
            this.btobLogo = template.icon.thumbnailImageURL
          }
        }
      })

    try {
      this.link.contact = (this.$store.state as State).playerMeta.senderEmail
      const split = this.link.contact.split('@')
      if (split.length > 1) {
        this.link.contactDomain = split[1]
      } else {
        this.link.contactDomain = 'liteview.jp'
      }
    } catch (e) {
      this.$log.error(e)
    }
  }

  focus(key: string) {
    this.loginFocused[key] = true
  }

  validateLoginForm(showError: boolean) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const email = this.login.email && this.login.email.length && re.test(this.login.email) ? '' : this.$tc('player.error.msg.email_required')
    const password = this.login.password && this.login.password.length ? '' : this.$tc('player.error.msg.password_required')
    let passwordConfirm = this.login.passwordConfirm && this.login.passwordConfirm.length ? '' : this.$tc('player.error.msg.password_confirm_required')
    if (!passwordConfirm && this.login.password !== this.login.passwordConfirm) {
      passwordConfirm = this.$tc('player.error.msg.password_confirm_error')
    }
    this.loginErrors.validated = !email && !password && !passwordConfirm
    this.isCarrierMail = !email && !!this.login.email && spamMailList.filter(domain => this.login.email.endsWith(domain)).length > 0
    if (showError && this.submitted.login) {
      if (this.loginFocused.email) {
        this.loginErrors.email = email
      }
      if (this.loginFocused.password) {
        this.loginErrors.password = password
      }
      if (this.loginFocused.passwordConfirm) {
        this.loginErrors.passwordConfirm = passwordConfirm
      }
    } else {
      if (!email) {
        this.loginErrors.email = email
      }
      if (!password) {
        this.loginErrors.password = password
      }
      if (!passwordConfirm) {
        this.loginErrors.passwordConfirm = passwordConfirm
      }
    }
  }

  handleLoginSubmit(event?: Event) {
    event?.preventDefault()
    event?.stopPropagation()

    this.submitted.login = true
    this.focus('email')
    this.focus('password')
    this.validateLoginForm(true)
    if (!this.loginErrors.validated) {
      return
    }

    if (this.login.email.indexOf('+') >= 0 && !this.login.email.endsWith('@pulit.jp') && !this.login.email.endsWith('@liteview.jp')) {
      this.showAlertMessage(this.$tc('player.error.msg.email_plus'))
      return
    }

    if (this.isCarrierMail && !this.carrierMailChecked) {
      const name = JoinMailInfoModal.NAME + '-' + Math.random()
      this.showModal(name, JoinMailInfoModal, {
        domain: this.link.contactDomain,
        onCheck: () => {
          this.carrierMailChecked = true
          this.handleLoginSubmit()
        }
      })
      return
    }

    this.$Progress.start()
    this.submitting = true
    PlayerApiService.thirdOAuthSync(this.oauthType, this.tmpUID, `email=${encodeURIComponent(this.login.email)}&pass=${encodeURIComponent(this.login.password)}`)
      .pipe(
        mergeMap((syncRes): Observable<[string, string]> => {
          if (syncRes.errorCode === Proto.ErrorCode.OK) {
            return of([syncRes.oauthToken, syncRes.oauthEmail])
          } else if (syncRes.errorCode === Proto.ErrorCode.THIRD_USER_DUPLICATE) {
            return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.kintone_duplicated')))
          }
          if (syncRes.message) {
            return throwError(new MessageError(ErrorCode.ServerMessage, syncRes.message))
          }
          return throwError(new MessageError(ErrorCode.ServerMessage, this.$tc('player.text.login.error.no_kintone_member')))
        }),
        finalize(() => {
          this.$Progress.finish()
          this.submitting = false
        })
      )
      .subscribe((url) => {
        this.result(url)
        this.hide()
      }, e => {
        this.$log.error(e)
        this.showAlert(e)
      })
  }

  hide() {
    super.hide()
  }

  showPassword() {
    this.visiblePasswd = !this.visiblePasswd
  }
}
