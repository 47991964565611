























import { Component, Watch } from 'vue-property-decorator'
import { AbstractVue } from '@/views/AbstractVue'
import { State } from '@/store'
import BlockModal from '@/player/components/modal/BlockModal.vue'
import RatingModal from '@/player/components/modal/RatingModal.vue'
import { PlayerApiService } from '@/player/service/api.service'
import { takeUntil } from 'rxjs/operators'
import { BrowserService } from '@/service/browser.service'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let WebFont: any
let webFontLoaded = false

@Component({})
export default class App extends AbstractVue {
  scheduleData = ''

  created() {
    PlayerApiService.maintenanceSchedule()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(schedule => {
        this.scheduleData = schedule
      })

    let existList = document.body.querySelectorAll('[data-lid="custom-body"]')
    for (const exist of existList) {
      exist.remove()
    }
    existList = document.body.querySelectorAll('[data-lid="custom-head"]')
    for (const exist of existList) {
      exist.remove()
    }

    if (!webFontLoaded) {
      webFontLoaded = true
      try {
        WebFont.load({
          google: {
            families: ['Roboto:400,700,italic', 'Noto Sans JP:400,700,900,italic', 'Lato:400,700,italic', 'Lora:400,700,italic', 'Merriweather:400,700,italic', 'Montserrat:400,700,italic', 'Noto Serif JP:400,700,italic', 'Oswald:400,700,italic', 'Playfair Display:400,700,italic', 'Roboto Slab:400,700,italic']
          },
          classes: false,
          events: false
        })
      } catch (e) {
        this.$log.error(e)
      }
    }

    try {
      if (BrowserService.isApp()) {
        const viewport = document.querySelector('meta[name="viewport"]')
        if (viewport) {
          viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no')
        }
      }
    } catch (e) {
      this.$log.error(e)
    }
  }

  destroyed() {
    super.destroyed()
  }

  get blocked(): boolean {
    return (this.$store.state as State).playerAuth.blocked
  }

  @Watch('blocked')
  onWatchBlocked() {
    if (this.$route.fullPath.startsWith('/order')) {
      return
    }
    if (this.blocked) {
      const name = BlockModal.NAME + '-' + Math.random()
      this.showModal(name, BlockModal, {
        planName: (this.$store.state as State).playerMeta.template?.name
      }, {
        clickToClose: true
      })
    }
  }

  get isAdult(): boolean {
    return (this.$store.state as State).playerMeta.isAdult
  }

  @Watch('isAdult')
  onWatchAdult() {
    if (this.isAdult) {
      const name = RatingModal.NAME + '-' + Math.random()
      this.showModal(name, RatingModal, {}, {
        width: '100%',
        height: '100%',
        scrollable: false
      })
    }
  }
}
