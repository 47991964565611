


















































import { Component } from 'vue-property-decorator'
import { AbstractModalVue } from '@/views/AbstractModalVue'
import SiteViolationModal from '@/player/components/modal/SiteViolationModal.vue'

@Component({
  components: {}
})
export default class RatingModal extends AbstractModalVue {
  public static NAME = 'rating-modal'
  types: [number, string][] = []
  propertiesDetailReadMore = false

  created() {
    for (let i = 1; i <= 8; i++) {
      this.types.push([i, this.$tc(`player.text.modal.site_violation.type_${i}`)])
    }
  }

  readMoreDetail(): void {
    this.propertiesDetailReadMore = !this.propertiesDetailReadMore
  }

  openViolation() {
    const name = SiteViolationModal.NAME + '-' + Math.random()
    this.showModal(name, SiteViolationModal, {})
  }

  back() {
    history.back()
  }

  hide() {
    super.hide()
  }
}
