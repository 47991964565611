import Vue from 'vue'
import { ToastPlugin, VBTogglePlugin, VBTooltipPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { SidebarPlugin } from 'bootstrap-vue/src/components/sidebar'

Vue.use(ToastPlugin)
Vue.use(SidebarPlugin)
Vue.use(VBTogglePlugin)
Vue.use(VBTooltipPlugin)
