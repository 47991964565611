import { ErrorActionPayload } from '@/store/error/action'

export type State = {
  error?: Error;
}

export const state: State = {
  error: undefined
}

const mutations = {
  [ErrorActionPayload.type](state: State, payload: ErrorActionPayload) {
    state.error = payload.error
  }
}

export default {
  state,
  mutations
}
