import { LoginToken } from '@/player/model/json'
import { Proto } from '@/player/model/proto'

const namespace = 'playerAuth/'

const LOGIN = namespace + 'LOGIN'
const LOGOUT = namespace + 'LOGOUT'
const CP = namespace + 'CP'
const AUTHENTICATION_FAILED = namespace + 'AUTHENTICATION_FAILED'
const BLOCKED = namespace + 'BLOCKED'
const CUSTOMDOMAINLOGIN = namespace + 'CUSTOMDOMAINLOGIN'

export class PlayerLoginActionPayload {
  public static type = LOGIN
  public readonly type = LOGIN

  constructor(readonly uuid: string, readonly user: Proto.IUser) {
  }
}

export class PlayerCPActionPayload {
  public static type = CP
  public readonly type = CP

  constructor(readonly companyUID: string | null) {
  }
}

export class PlayerLogoutActionPayload {
  public static type = LOGOUT
  public readonly type = LOGOUT
}

export class PlayerAuthFailedPayload {
  public static readonly type = AUTHENTICATION_FAILED
  public readonly type = AUTHENTICATION_FAILED
}

export class PlayerAuthBlockPayload {
  public static readonly type = BLOCKED
  public readonly type = BLOCKED

  constructor(readonly blocked: boolean) {
  }
}

export class CustomDomainLoginActionPayload {
  public static type = CUSTOMDOMAINLOGIN
  public readonly type = CUSTOMDOMAINLOGIN

  constructor(readonly token: LoginToken | null) {
  }
}
